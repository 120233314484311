@import '../../../styles/sizes.scss';
@import '../../../styles/mixins.scss';

.home_widget {
  padding: 35px;

  &__title {
    max-width: $homeWidgetMaxWidth;
  }
}

.category_widget {
  border: 1px solid var(--bg-main-color-4);
}

.home_widget,
.category_widget {
  position: relative;
  border-radius: 8px;
  display: flex;
  flex-direction: contain;
  align-items: flex-start;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;

  button {
    width: 200px;
  }

  &__texts_wrapper {
    font-family: Inter-Bold;
    line-height: 1.2;
    color: var(--text-secondary);
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 20px;
    max-width: $homeWidgetMaxWidth;
  }

  .action-btn {
    position: absolute;
    bottom: 30px;
    margin-top: 40px;
    padding: 0 20px;
    height: $btn-md-height;
    border-radius: 8px;
    outline: none;
    font-family: Inter-Bold;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    transition: 0.35s ease-out;
    text-transform: uppercase;
  }
}

.tablet .home_widget,
.tablet .category_widget {
  padding: 0 24px;
}

.mobile .home_widget {
  width: 100%;
  padding: 28px 24px 15px;
  height: $homeWidgetHeight;
  margin-bottom: 10px;

  &__title {
    font-size: 18px;
    line-height: 22px;
    max-width: $homeWidgetTitleMaxWidth;
  }

  &__subtitle {
    font-size: 10px;
    line-height: 15px;
    max-width: $homeWidgetSubtitleMaxWidth;
    top: 65px;
    left: 23px;

    &:nth-child(2) {
      max-width: $homeWidgetSubtitleMaxWidth;
    }
  }

  .action-btn {
    height: $homeWidgetBtnHeight;
    left: 23px;
    font-family: Inter-Bold;
    font-size: 12px;
    line-height: 15px;
  }
}

.mobile {
  .home_widget_template_2-4 {
    padding: 0 10px;
    display: grid;
    column-gap: 6px;
    row-gap: 4px;
    grid-template-columns: repeat(4, 1fr);

    .home_widget {
      height: 120px;
      margin-bottom: 0;
      border-radius: 0;
    }

    .category_widget {
      height: 90px;
      border-radius: 0;
    }

    .home_widget_1 {
      grid-column: 1 / span 2;
    }

    .home_widget_2 {
      grid-column: 3 / span 2;
    }
    .category_widget_3 {
      grid-column: 1;
      grid-row: 3;
    }

    .category_widget_4 {
      grid-column: 2;
      grid-row: 3;
    }

    .category_widget_5 {
      grid-column: 3;
      grid-row: 3;
    }

    .category_widget_6 {
      grid-column: 4;
      grid-row: 3;
    }
  }
  .home_widget_template_2-3 {
    padding: 0 12px;
    display: grid;
    column-gap: 10px;
    grid-template:
      'homeWidget_1 homeWidget_1 homeWidget_1'
      'homeWidget_2 homeWidget_2 homeWidget_2'
      'categoryWidget_1 categoryWidget_2 categoryWidget_3';

    .home_widget_1 {
      grid-area: homeWidget_1;
    }

    .home_widget_2 {
      grid-area: homeWidget_2;
    }

    .category_widget_3 {
      grid-area: categoryWidget_1;
    }

    .category_widget_4 {
      grid-area: categoryWidget_2;
    }

    .category_widget_5 {
      grid-area: categoryWidget_3;
    }
  }

  .category_widget {
    height: 130px;
    width: 100%;
    border-radius: 16px;
  }

  .home_widget {
    border-radius: 16px;
  }
}

.desktop {
  .home_widget_template_2-4 {
    @supports (aspect-ratio: 1 / 1) {
      display: grid;
      row-gap: 6px;
      column-gap: 4px;
      grid-template:
        "home_widget_1 home_widget_2 category_widget_3 category_widget_4"
        "home_widget_1 home_widget_2 category_widget_5 category_widget_6";
      grid-template-columns: repeat(4, 1fr);

      .home_widget,
      .category_widget {
        background-size: contain;
        background-position: unset;
      }

      .home_widget_1 {
        aspect-ratio: 1/ 0.935;
        grid-area: home_widget_1;
      }

      .home_widget_2 {
        aspect-ratio: 1/ 0.935;
        grid-area: home_widget_2;
      }

      .category_widget_3 {
        aspect-ratio: 1/0.455;
        grid-area: category_widget_3;
      }

      .category_widget_4 {
        aspect-ratio: 1/0.455;
        grid-area: category_widget_4;
      }

      .category_widget_5 {
        aspect-ratio: 1/0.455;
        grid-area: category_widget_5;
      }

      .category_widget_6 {
        aspect-ratio: 1/0.455;
        grid-area: category_widget_6;
      }

    }
  }


  .home_widget_template_2-3 {
    display: grid;
    row-gap: 46px;
    column-gap: 10px;
    grid-template:
      'homeWidget_1 homeWidget_1 homeWidget_1 homeWidget_2 homeWidget_2 homeWidget_2' 274px
      'categoryWidget_1 categoryWidget_1 categoryWidget_2 categoryWidget_2 categoryWidget_3 categoryWidget_3' 130px;

    .home_widget_1 {
      grid-area: homeWidget_1;
    }

    .home_widget_2 {
      grid-area: homeWidget_2;
    }

    .category_widget_3 {
      grid-area: categoryWidget_1;
    }

    .category_widget_4 {
      grid-area: categoryWidget_2;
    }

    .category_widget_5 {
      grid-area: categoryWidget_3;
    }

    @supports (aspect-ratio: 1 / 1) {
      grid-template:
        'homeWidget_1 homeWidget_1 homeWidget_1 homeWidget_2 homeWidget_2 homeWidget_2' auto
        'categoryWidget_1 categoryWidget_1 categoryWidget_2 categoryWidget_2 categoryWidget_3 categoryWidget_3' auto;

      .home_widget_1,
      .home_widget_2 {
        aspect-ratio: 1/0.3965;
      }

      .category_widget_3,
      .category_widget_4,
      .category_widget_5 {
        aspect-ratio: 1/0.2842;
      }
    }
  }

  .home_widget_template_3-1 {
    display: grid;
    padding: 0 0 0 10px;
    grid-template:
      'homeWidget_1 homeWidget_2 homeWidget_3' 370px
      'categoryWidget_4 categoryWidget_4 categoryWidget_4' 100px;

    .home_widget_1 {
      grid-area: homeWidget_1;
      margin: 10px 0 10px;
      cursor: unset;
      border: 1px solid var(--border-primary);
    }

    .home_widget_2 {
      grid-area: homeWidget_2;
      margin: 10px;
      cursor: unset;
      border: 1px solid var(--border-primary);
    }

    .home_widget_3 {
      grid-area: homeWidget_3;
      margin: 10px 0 10px;
      cursor: unset;
      border: 1px solid var(--border-primary);
    }

    .category_widget_4 {
      grid-area: categoryWidget_4;
      margin: 10px 0;
      border: 1px solid var(--border-primary);
    }
  }

  .home_widget_template_4 {
    @include center();
    height: auto;
    width: 100%;
    margin: 70px auto 0;

    div {
      height: 40vh;
      width: 14vw;
      min-width: 200px;
      background-size: contain;
      background-repeat: no-repeat;
      padding: 0;
      transition: all 1s ease;

      &:hover {
        transform: scale(1.07);
      }
    }
  }
}

.tablet {
  .home_widget_template_2-3 {
    display: grid;
    row-gap: 20px;
    column-gap: 10px;
    padding: 0 24px 60px;
    grid-template:
      'homeWidget_1 homeWidget_1 homeWidget_1 homeWidget_2 homeWidget_2 homeWidget_2' 180px
      'categoryWidget_1 categoryWidget_1 categoryWidget_2 categoryWidget_2 categoryWidget_3 categoryWidget_3' 70px;

    .home_widget_1 {
      grid-area: homeWidget_1;
    }

    .home_widget_2 {
      grid-area: homeWidget_2;
    }

    .category_widget_3 {
      grid-area: categoryWidget_1;
    }

    .category_widget_4 {
      grid-area: categoryWidget_2;
    }

    .category_widget_5 {
      grid-area: categoryWidget_3;
    }
  }

  .home_widget_template_3-1 {
    display: grid;
    padding: 0 0 0 10px;
    grid-template:
      'homeWidget_1 homeWidget_2 homeWidget_3' 370px
      'categoryWidget_4 categoryWidget_4 categoryWidget_4' 100px;

    .home_widget_1 {
      grid-area: homeWidget_1;
      margin: 10px 0 10px;
      cursor: unset;
      border: 1px solid var(--border-primary);
    }

    .home_widget_2 {
      grid-area: homeWidget_2;
      margin: 10px;
      cursor: unset;
      border: 1px solid var(--border-primary);
    }

    .home_widget_3 {
      grid-area: homeWidget_3;
      margin: 10px 0 10px;
      cursor: unset;
      border: 1px solid var(--border-primary);
    }

    .category_widget_4 {
      grid-area: categoryWidget_4;
      margin: 10px 0;
      border: 1px solid var(--border-primary);
    }
  }

  .home_widget_template_4 {
    @include center();
    height: auto;
    width: 100%;
    margin: 70px auto 0;

    div {
      height: 40vh;
      width: 14vw;
      min-width: 200px;
      background-size: contain;
      background-repeat: no-repeat;
      padding: 0;
      transition: all 1s ease;

      &:hover {
        transform: scale(1.07);
      }
    }
  }
}

@keyframes scale {
  to {
    transform: scale(1.2);
  }
}
