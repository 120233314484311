@import '../../../styles/sizes.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/constants';

.providers_accordion_wrapper {
  position: relative;

  .title {
    margin: 0;

    span {
      margin-left: 5px;
    }
  }

  .providers_accordion_container {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    display: flex;
    align-items: center;
    border: 1px solid var(--border-primary);
    padding: 0 8px;

    &.openProviderList {
      padding: 0px;
    }

    .pd {
      display: flex;
    }

    .provider_wrapper {
      button svg path {
        fill: var(--text-secondary);
      }

      .providers_container {
        background: var(--background-surface-1);
        border-radius: 8px;
        padding: 0;
        margin: 0px auto;
        z-index: $provider-filter__container--index;
        max-width: $maxWidth;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        .cards {
          display: flex;
          flex-wrap: wrap;
          margin: 10px 26px 44px;
          justify-content: flex-start;
        }
      }

      .provider_collapse {
        font-size: 14px;
        margin: 0 auto;
        min-width: 146px;
        padding: 0 12px;
        border-radius: 8px;
        height: $btn-lg-height;
        text-transform: capitalize;

        &.active {
          position: absolute;
          top: 8px;
          right: 48px;
          width: fit-content;

          svg path {
            fill: var(--icon-color-primary);
          }
        }

        .arrow {
          cursor: pointer;
          margin: 0 0 0 30px;
        }

        .up {
          transform: rotate(180deg);
        }

        svg path {
          fill: var(--icon-color-primary);
        }
      }
    }
    .providers_icons {
      width: 100%;
      svg path {
        fill: var(--text-tag) !important;
      }
    }

    &.withButtons {
      padding: 0px 40px;
    }

    .provider_search_container {
      height: 44px;
      width: 680px;
      margin: 8px auto;
      background: var(--background-surface-2);
      display: flex;
      border: 1px solid var(--border-primary);
      border-radius: 10px;

      div:nth-child(1) {
        width: 100%;
        input {
          height: 42px !important;
        }
      }

      .icon_wrapper {
        width: 63px;
        height: 44px;
        background-color: var(--background-surface-3);
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        margin-right: 0;
        margin-top: -1px;

        .icon {
          position: relative;
          right: 35px;
          top: 10px;
          cursor: pointer;

          svg path {
            fill: var(--text-secondary);
          }

          div {
            position: relative;
            right: -36px;
            top: 0;
          }
        }
      }
    }

    .close_icon {
      position: absolute;
      left: 34px;
      top: 8px;
      cursor: pointer;
      background: var(--account-background-surface-3);
      border-radius: 50%;
      height: 30px;
      width: 30px;
      @include center();
    }

    .providers_wrapper {
      display: flex;
      height: 60px;
      justify-content: flex-start;
      text-align: center;
      overflow: hidden;
      width: 100%;
      max-width: calc($maxWidth - 230px);
      align-items: center;
      position: relative;

      .provider_button {
        &:first-child {
          margin: 8px 8px 8px 0;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .control_icon_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 2px;
        top: 29%;
        width: 25px;
        height: 25px;
        border-radius: 4px;
        background: var(--background-surface-2);
        border: 1px solid var(--border-primary);
        margin: 0 10px;
        cursor: pointer;
        z-index: $icon-index;

        > i {
          color: var(--text-secondary);
          font-size: 10px;
        }

        &.prev {
          right: unset;
          left: 2px;
        }
      }
    }
  }
  .providers_accordion_wrapper_opened {
    display: block;
  }
}

.providers_list_container {
  width: 50%;
  display: flex;
  justify-content: flex-end;

  .provider_wrapper {
    width: $provider_btn_min_width;
    float: right;
    position: relative;
    right: 8px;
    border: 1px solid var(--button-primary-border);
    border-radius: 8px;
    color: var(--text-primary);

    button {
      color: var(--text-primary);

      svg path {
        fill: var(--text-primary);
      }

      span div {
        justify-content: space-between;
        color: var(--text-secondary);
        align-items: center;
      }
    }

    .provider_collapse {
      width: $provider_btn_min_width;
      margin-top: -7px;
      margin-left: 10px;
      text-transform: capitalize;
    }

    .providers_container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      position: absolute;
      right: -9px;
      top: 44px;
      width: 1351px;
      color: var(--text-secondary);
      background-color: var(--background-surface-2);
      margin: 0 auto;
      border-radius: 7px;
      padding: 10px 10px 25px;
      animation: appearance 1s ease;
      z-index: $provider-filter__providers-container--index;

      .provider_card_wrapper {
        align-items: center;
        display: inline-block;
        flex-direction: column;
        justify-content: center;
        margin: 5px;

        .provider_button {
          align-items: center;
          background-color: var(--tag-primary);
          border: 1px solid var(--tag-primary-border);
          border-radius: 5px;
          color: var(--text-primary);
          display: inline-flex;
          font-size: 12px;
          justify-content: center;
          position: relative;
          text-transform: uppercase;
          width: fit-content;
          > div {
            width: fit-content;
            svg {
              width: 76px;
            }
          }

          &.selected {
            background-color: var(--tag-primary-selected);
          }

          &:hover {
            background-color: var(--tag-primary-hover);
          }

          img {
            max-height: 30px;
          }

          /*  span {
            position: absolute;
            max-width: 80px;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
          }*/
        }

        .provider_name {
          display: block;
          text-transform: capitalize;
          font-size: 12px;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 100px;
          text-align: center;
          margin: 0 auto;
          color: #8499a5; // TODO need to find a variable for it.
        }
      }
      .provider_card_wrapperForLightTheme {
        svg path {
          fill: black;
        }
      }
    }

    .provider_collapse {
      font-family: Inter-SemiBold;
      font-size: 14px;
      margin: 0 auto;
      width: 100%;
      text-transform: capitalize;

      .arrow {
        cursor: pointer;
        margin: 0 0 0 15px 0;
      }

      .up {
        transform: rotate(180deg);
      }
    }
  }

  &.withButtons {
    padding: 0px 40px;
  }

  &.blurRight {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 35px;
      border-radius: 0 10px 10px 0;
      background: linear-gradient(270deg, rgba(0, 0, 0, 0.89) 29.88%, rgba(0, 0, 0, 0) 89.93%);
      backdrop-filter: blur(1px);
      z-index: 0;
    }
  }

  &.blurLeft {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 35px;
      border-radius: 10px 0 0 10px;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.89) 29.88%, rgba(0, 0, 0, 0) 89.93%);
      backdrop-filter: blur(1px);
      z-index: 0;
    }
  }
}

.desktop {
  .providers_accordion_wrapper {
    margin: 30px 0;
  }
}

.provider_dropdown {
  display: flex;
  align-items: center;
  z-index: $provider-filter__provider_dropdown--index;

  .checkbox-dropdown {
    border-radius: 10px;
    background-color: var(--background-surface-2);
    border: 1px solid var(--border-primary);
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    position: relative;
    margin: 0 auto;
    user-select: none;
    height: 48px;
    cursor: pointer;

    .provider_title {
      font-size: 16px;
      color: var(--text-primary);
      margin: 5px 0 0 5px;
    }

    .count_badge {
      @include center();

      background-color: var(--background-surface-1);
      height: 24px;
      width: 24px;
      border-radius: 50%;
      margin-left: 10px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      color: var(--text-secondary);
    }

    svg path {
      fill: white;
    }
  }

  .checkbox-dropdown:after {
    content: '';
    height: 0;
    position: absolute;
    width: 0;
    border: 6px solid transparent;
    border-top-color: white;
    top: 50%;
    right: 10px;
    margin-top: -3px;
  }

  .checkbox-dropdown.is-active:after {
    border-bottom-color: white;
    border-top-color: transparent;
    margin-top: -9px;
  }

  .checkbox-dropdown-list {
    @include scrollbar();

    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 100%;
    border: inherit;
    border-top: none;
    left: -1px;
    right: -1px;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    height: 280px;
    overflow: scroll;
    overflow-x: hidden;
    pointer-events: none;
  }

  .is-active .checkbox-dropdown-list {
    @include scrollbar();

    opacity: 1;
    pointer-events: auto;
    z-index: $provider-filter__active-checkbox-dropdown-list--index;
    margin-top: 5px;
    border-radius: 10px;
    background-color: var(--background-surface-2);
  }

  .checkbox-dropdown-list li {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--border-primary);
    padding: 10px;

    label {
      display: block;
      transition: all 0.2s ease-out;
      color: var(--text-secondary);
      margin: 0 5px 0 0;

      input[type='checkbox'] {
        margin-right: 10px;
      }
    }

    .provider_image_wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .provider_image {
        height: 25px;
        width: fit-content;
      }
    }
  }

  .checkbox-dropdown-list li:hover {
    background-color: var(--background-surface-3);
    color: var(--text-secondary);
  }
}

.desktop,
.tablet {
  .providers_accordion_wrapper {
    .title {
      font-style: normal;
      font-family: Inter-Bold;
      font-size: 20px;
      line-height: 24px;
      color: var(--text-secondary);
      overflow: hidden;
      text-align: inherit;
      margin-bottom: 15px;
    }
  }
}

.tablet {
  .providers_accordion_container {
    margin-bottom: 20px;

    .provider_search_container {
      width: 350px;
    }
  }

  .providers_accordion_wrapper .providers_accordion_container .provider_wrapper .providers_container {
    max-width: calc(100vw - 48px);
  }
}

.providers {
  background: var(--background-surface-2);
  border: 1px solid var(--border-primary);
  height: $gameTags_container_desktop_height;
  margin-bottom: 40px;
  position: relative;
  border-radius: 8px;

  .providers_list {
    display: flex;
    height: 60px;
    justify-content: flex-start;
    scroll-behavior: smooth;
    text-align: center;
    overflow-x: clip;
    width: 100%;
    align-items: center;
  }

  .providers_list_wrapper {
    display: flex;
    align-items: center;
    height: inherit;
    justify-content: space-between;
  }
}

@keyframes appearance {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.provider_card_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .provider_name {
    display: block;
    text-transform: capitalize;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100px;
    text-align: center;
    margin: 0 auto;
  }

  &.provider_button {
    width: fit-content;
    height: $provider_btn_height;
    padding: 3px 10px;
    font-size: 12px;
    font-family: Inter-SemiBold;
    margin: 8px 7px 8px 0;
    border-radius: 8px;
    text-transform: uppercase;
    position: relative;

    > div {
      width: fit-content;
      svg {
        width: 76px;
      }
    }

    img {
      width: $provider_img_width;
      height: $provider_img_height;
      object-fit: contain;
    }

    span {
      width: 100%;
    }
  }
}

.mobile .providers_filter_mobile_container {
  color: var(--text-secondary);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 79%;

  .title {
    font-family: Inter-SemiBold;
    font-size: 16px;
    color: var(--text-secondary);
    padding: 29px 0 8px;
    margin: 0;
  }

  label {
    font-family: Inter-SemiBold;
    font-size: 14px;
    color: var(--text-secondary);
    margin-top: 0.5rem;
  }

  .provider_collapse {
    height: $search_btn_size;
    width: 100%;
    border-radius: 8px;
    text-transform: capitalize;

    span p {
      justify-content: space-between;
      text-transform: capitalize;
    }

    span div {
      display: flex;
      justify-content: space-between;
    }
    .provider_title {
      color: var(--text-primary);
    }
  }
  .count_badge {
    font-size: 0.8rem;
    display: flex;
    align-items: baseline;
    gap: 10px;
  }
}

.backdrop_provider {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(26, 26, 28, 0.6);
  will-change: opacity;
  z-index: calc($provider-filter__providers-accordion-wrapper-opened--index - 1);
}

.providers_accordion_wrapper_opened {
  z-index: $provider-filter__providers-accordion-wrapper-opened--index;
  background: var(--background-surface-1);
}
.provider_filter {
  width: 94%;
  position: fixed;
  bottom: 5px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
