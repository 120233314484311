@import '../../../styles/mixins.scss';
@import '../../../styles//constants/template.scss';

.sidebar_global_container--opened {
  .sidebar-section {
    .sidebar_section__item {
      @include center();

      position: relative;
      height: 100%;

      & > div {
        width: unset;
      }
    }

    .panel {
      margin: 0 auto;
      width: $sidebarWidth;
      transition: 0.4s;
      overflow: hidden;
      list-style: none;
      text-align: initial;
      padding-left: 25px;

      li {
        height: 37px;
        font-size: 14px;
        line-height: 17px;
        align-items: center;
        display: flex;
        color: var(--navigation-text);
        cursor: pointer;

        &:hover {
          color: var(--navigation-text-hover);
        }
      }

      .selected {
        color: var(--navigation-text-selected);
      }

      .active_tag {
        color: var(--secondary-5);
      }
    }

    .arrow {
      position: absolute;
      right: 5px;

      &.rotateArrow {
        transform: translateY(-50%) rotate(0deg) !important;
      }
    }

    .sidebar_section__item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: $sidebarWidth;

      &__text {
        display: block;
        margin-left: 10px;
      }

      .arrow {
        display: block;
      }

      .category_icon {
        margin-right: unset;
      }
    }

    .panel {
      display: block;
    }
  }
  .label-image {
    max-height: 16px;
    width: auto;
    position: absolute;
    top: -5px;
    right: 18px;
    filter: unset;
    height: auto;
  }
}
.secondary-btn {
  border: 1px solid var(--navigation-nav-surface-selected-border);
}
.sidebar_section__item {
  @include center();

  cursor: pointer;
  height: 40px;
  width: 40px;
  font-size: 16px;
  line-height: 45px;
  font-family: Inter-Bold;
  border-radius: 4px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  .arrow {
    position: absolute;
    right: -8px;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg) !important;
  }

  &__text {
    font-size: 14px;
    display: none;
  }

  img {
    filter: brightness(0) invert(1);
    height: 24px;
    width: 24px;
  }

  div {
    display: flex;
    align-items: center;
    width: 24px;

    .category_icon {
      svg {
        fill: var(--text-secondary);
        height: 24px;
        width: 24px;
      }
    }
  }

  .menu_content_text {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  span {
    margin-right: 10px;
  }

  .arrow {
    transform: rotate(0deg);
    margin-right: 0;
    display: none;
  }

  .rotateArrow {
    transform: rotate(180deg);
    border-radius: 3px 3px 0 0;
  }

  .promotions {
    text-transform: uppercase;

    span {
      margin-right: 10px;
      color: var(--main-text-color-6);
    }

    .btn_promotion_icon {
      font-size: 14.5px;
      margin-right: 10px;
    }
  }
}
