@import '../../../styles/sizes.scss';

.licence_wrapper {
  grid-area: licence;
  display: flex;
  align-items: center;

  .image_licence {
    // min-width: 140px;
    width: 100px;
    height: auto;

    svg {
      border-radius: 8px;
    }
  }

  iframe {
    height: $licence_wrapper_height;
    min-width: $licence_iframe_min_width;
    max-width: $licence_iframe_max_width;
    border: none;
  }

  a {
    height: 50px;
    width: auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.mobile .licence_wrapper {
  margin: 20px 0;
  .curacao_icon {
    margin-right: 35px;
  }
}
