@import '../../../styles/mixins.scss';
@import '../../../styles/constants/';

.sidebar_promotion_section {
  @include center();

  flex-wrap: wrap;
  width: 58px;
  margin: 8px 0 16px;

  .promotion-item {
    padding: 0;
    position: relative;
    margin: 3px 0;
    height: 58px;

    &:last-child {
      width: 100%;
    }

    &__image {
      height: 58px;
    }

    &__text {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin-left: 4px;
      font-size: 12px;
      color: var(--text-primary);
      text-transform: capitalize;
    }

    &.large {
      .promotion-item__text {
        margin-left: 12px;
        font-size: 14px;
      }
    }
    &__order {
      &:nth-child(1) {
        order: 1;
        margin-bottom: 24px;
        margin-top: 8px;
      }
      &:nth-child(3) {
        order: 2;
      }
      &:nth-child(2) {
        order: 3;
      }
      &:nth-child(4) {
        order: 4;
        height: 16px;
        margin-bottom: 8px;
      }
    }
  }
  button {
    background: unset!important;
  }
}

.sidebar_global_container {
  button {
    background-color: var(--navigation-nav-surface-2);
    &:hover {
      background-color: var(--navigation-nav-surface-2-hover);
    }
  }
  button.selected {
    background-color: var(--navigation-nav-surface-2-selected);
    border: 1px solid var(--navigation-nav-surface-selected-border);
  }
  &--opened {
    .sidebar_promotion_section {
      width: $sidebarWidth + 16px;
      justify-content: space-between;

      .promotion-item {
        animation: swingParent 0.5s ease-in;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          width: 47%;
        }

      /*  &:hover {
          box-shadow: 2px 3px 10px 0px rgba(147, 134, 231, 0.3);
        }*/
      }
    }
  }

/*  .sidebar_promotion_section {
    .promotion-item {
      &:hover {
        box-shadow: 2px 3px 10px 0px rgba(147, 134, 231, 0.3);
      }
    }
  }*/
}

@keyframes swingParent {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
