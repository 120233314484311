.textBlock_wrapper {
  font-size: 14px;
  font-family: Inter;
  line-height: 17px;
  grid-area: operator_block;
  color: var(--text-secondary);
  margin: 0;
  display: flex;
  align-items: center;
}

.mobile .textBlock_wrapper {
  font-size: 12px;
  margin: 20px 0;
  .ministerios-block {
    display: block;
  }
}
