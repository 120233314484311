@import '../../styles/templates/template_noFullScreen.scss';
@import '../../styles/templates/template_fullScreen.scss';
@import '../../styles/templates/template_with_sidebar.scss';
@import '../../styles/templates/light_theme.scss';
@import '../../styles/templates/dark-light-theme-1.scss';

.acceptCookies_container {
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin: 0 5px 0 0;
  }
  .acceptCookies_container_btns {
    display: flex;
  }
  .acceptCookies_container_btns_mobile {
    display: flex;
    margin-top: 15px;
  }
  button {
    margin: 0 5px;
    height: 30px;
    max-width: 20vw;
  }
}
.acceptCookies_container_mobile {
  .acceptCookies_container_btns_mobile {
    margin-top: unset;
  }
}
