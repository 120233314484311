@import '../../../../../../../styles/mixins.scss';
.container {
  @include center();
  flex-direction: column;
  height: 100%;
  p {
    color: var(--text-primary);
    max-width: 600px;
  }
  .verifyBtn {
    background-color: var(--button-primary);
    color: var(--button-primary-text);
    width: fit-content;
    height: 30px;
    border-radius: 8px;
  }
}
