@import '../../styles/sizes.scss';
@import '../../styles/mixins.scss';
@import '../../styles/constants';

.desktop_header {
  background-color: var(--background-surface-1);
  margin: 0 auto;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $template-1__desktop-header--index;

  .header_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .firstSection {
      display: flex;
      align-items: center;
      height: 100%;
      .logo {
        margin-left: 15px;
      }
    }

    .secondSection {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .thirdSection {
      display: flex;
      justify-content: right;
      align-items: center;
    }
  }

  // templates--------------
  &.header-1Row-noFullScreen {
    background: var(--background-surface-2);
    border-radius: 4px;
    margin: 20px 40px 0;
    padding: 12px 16px;

    .topLine {
      height: 44px;
    }
  }

  &.header-1Row-sidebar {
    background: var(--background-surface-2);
    box-shadow: 10px 4px 24px 0px #0d0f18; //add shadow to palette
    .topLine {
      padding-bottom: 12px;
      .firstSection {
        width: 100%;
      }
    }

    .bottomLine {
      padding: 0 24px 12px;
      height: 44px;
      max-width: 1440px;
      margin: 0 auto;

    }

    &.desktop_header {
      left: unset;
      margin: 0;
    }
  }

  &.header-1Row {
    .topLine {
      height: $headerTopLineHeight-1Row;
    }
  }

  &.header-2Rows-with-nl-fullScreen {
    .topLine {
      height: $headerTopLineHeight-2Rows;
      width: 100%;
      display: block;
    }

    .bottomLine {
      min-height: $headerBottomLineHeight-2Rows;
      width: 100vw;
      padding: 0 25px;

      .firstSection {
        width: 70%;
      }

      .secondSection {
        flex-wrap: wrap;
        justify-content: flex-end;
        min-height: 100px;
        width: 40%;
      }
    }
  }

  &.header-3Rows-with_nl {
    .topLine {
      height: $headerTopLineHeight-3Row;
      display: block;
    }

    .middleLine {
      height: $headerMiddleLineHeight-3Rows;
      display: grid;
      grid-gap: 1%;
      grid-template-columns: 9% 40% 49%;
    }

    .bottomLine {
      height: $headerBottomLineHeight-3Rows;
      width: 100%;

      .firstSection {
        width: 100%;
      }
    }
  }

  &.header-2Rows-default-noFullScreen {
    .topLine {
      height: 74px;
      display: grid;
      grid-gap: 1%;
      grid-template-columns: 9% 40% 49%;
    }

    .bottomLine {
      width: 100%;
      height: 55px;
      display: block;

      .firstSection {
        width: 100%;
      }
    }
  }

  &.header-2Rows-with_nl-fullScreen {
    .topLine {
      height: 74px;
      display: grid;
      grid-gap: 1%;
      grid-template-columns: 9% 40% 49%;
    }

    .bottomLine {
      height: 55px;
      display: block;
      background-color: var(--background-surface-2);

      .firstSection {
        width: 70%;
      }
    }
  }

  &.header-3Rows {
    .topLine {
      height: 74px;
      display: block;
    }

    .middleLine {
      height: 66px;
      padding: 0 30px;
    }

    .bottomLine {
      height: 55px;
      width: 100%;
    }
  }
  &.fullWidth {
    .topLine {
      display: grid;
      grid-template-columns: 9% 40% 49%;
      height: 74px;
    }
    .bottomLine {
      display: block;
      height: 55px;
      min-height: 55px;
      width: 100%;
      padding: 0;
      .firstSection {
        width: 100%;
      }
    }
  }
}

//mobile header
.mobile_header_wrapper {
  display: flex;
  padding: 0 12px;
  align-items: center;
  border-bottom: 1px solid var(--border-primary);
  background-color: var(--background-surface-1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $header--index;
  flex-wrap: wrap;

  .header_row {
    display: flex;
    align-items: center;
  }

  // templates
  &.header-1Row {
    display: flex;
    justify-content: space-between;

    .top_row:nth-child(1) {
      width: 35%;
    }

    .top_row:nth-child(2) {
      width: 60%;
      justify-content: flex-end;
    }
  }

  &.header-2Rows-with-nl {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &:nth-child(2) {
      padding-left: 5px;
    }

    .top_row {
      width: 100%;
      display: flex;
    }

    .bottom_row {
      width: 49%;
      display: flex;

      &:last-child {
        justify-content: flex-end;
      }
    }
  }
}

.mobile_newsLetter {
  padding-top: 28px;
}

//tablet header
.tablet_header {
  background-color: var(--background-surface-1);
  margin: 0 auto;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $template-1__desktop-header--index;

  .header_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .firstSection {
      display: flex;
      align-items: center;
      height: 100%;
      width: 200px;
    }

    .secondSection {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .thirdSection {
      display: flex;
      justify-content: right;
      align-items: center;
    }
  }

  // templates--------------
  &.header-1Row-noFullScreen {
    background: var(--background-surface-2);
    border-radius: 4px;
    margin: 20px 40px 0;
    padding: 12px 16px;

    .topLine {
      height: 44px;
    }
  }

  &.header-1Row-sidebar {
    background: var(--background-surface-2);
    padding: 12px 0;
    box-shadow: 10px 4px 24px 0px #0d0f18; //add shadow to palette

    .topLine {
      height: 44px;
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 24px;
    }

    &.desktop_header {
      left: unset;
      margin: 0;
    }
  }

  &.header-1Row {
    .topLine {
      height: $headerTopLineHeight-1Row;
    }
  }

  &.header-2Rows-with-nl-fullScreen {
    .topLine {
      height: $headerTopLineHeight-2Rows;
      width: 100%;
      display: block;
    }

    .bottomLine {
      min-height: $headerBottomLineHeight-2Rows;
      width: 100vw;
      padding: 0 10px;

      .firstSection {
        width: 65%;
      }

      .secondSection {
        flex-wrap: wrap;
        justify-content: flex-end;
        min-height: 100px;
        width: 35%;
      }
    }
  }

  &.header-3Rows-with_nl {
    .topLine {
      height: $headerTopLineHeight-3Row;
      display: block;
    }

    .middleLine {
      height: $headerMiddleLineHeight-3Rows;
    }

    .bottomLine {
      height: $headerBottomLineHeight-3Rows;
      width: 100%;

      .firstSection {
        width: 100%;
      }
    }
  }

  &.header-2Rows-default-noFullScreen {
    .topLine {
      display: grid;
      grid-gap: 1%;
      grid-template-columns: 10% 10% 78%;
      padding: 0 24px;

      .firstSection {
        height: unset;
      }
    }

    .bottomLine {
      width: 100%;
      height: 55px;
      display: block;

      .firstSection {
        width: 100%;
      }
    }
  }

  &.header-2Rows-with_nl-fullScreen {
    .topLine {
      height: 74px;
      display: grid;
      grid-gap: 1%;
      grid-template-columns: 9% 40% 49%;
    }

    .bottomLine {
      height: 55px;
      display: block;
      background-color: var(--background-surface-2);

      .firstSection {
        width: 70%;
      }
    }
  }

  &.header-3Rows {
    .topLine {
      height: 74px;
      display: block;
    }

    .middleLine {
      height: 66px;
      padding: 0 30px;
    }

    .bottomLine {
      height: 55px;
      width: 100%;
    }
  }
}
