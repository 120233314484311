@import '../../styles/mixins.scss';

.sidebar_global_container {
  position: fixed;
  width: 72px;
  height: 100vh;
  left: 0;
  background: var(--navigation-nav-surface-1);
  overflow: hidden;
  transition: width 0.1s ease-out;
  box-shadow: 10px 4px 24px 0px #0d0f18; //add shadow to palette

  .sidebar_scrollable_content {
    &::-webkit-scrollbar {
      display: none;
    }

    overflow-y: auto;
    height: calc(100% - 68px); // sidebar menu btn height
  }

  .sidebar_row_content {
    @include center();

    min-height: 90px;
    width: 100%;

    .sidebar_promotion_section,
    .sidebar-section,
    .live_chat_button {
      border: 1px solid var(--navigation-nav-surface-selected-border);
      border-radius: 4px;
      padding: 8px;
    }

    .sidebar-section {
      margin-bottom: 16px;
    }

    .logo {
      display: none;
    }

    .social_networks_container {
      flex-direction: column;
      align-content: center;
      padding-bottom: 40px;
    }
  }

  &--opened {
    width: 260px;
    transition: width 0.1s ease-in;

    .sidebar_row_content {
      .logo {
        @include center();
        width: 100%;
        padding: 0 20px 0 0;
      }

      .social_networks_container {
        flex-direction: row;
      }
    }
  }
}
