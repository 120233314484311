@import '../../../../../styles/mixins.scss';

.raffle_draw_info_container {
  width: 100%;
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  .raffle_draw_img {
    width: 100%;
    min-height: 220px;
  }

  .raffle_draw_info_content {
    @include center;
    flex-direction: column;
    width: auto;
    position: absolute;
    left: 100px;
    top: 50%;
    transform: translateY(-50%);
    padding: 24px;
    background: rgba($color: #000000, $alpha: 0.4);
    border-radius: 8px;
  }

  .raffle_draw_name {
    font-size: 20px;
    font-family: 'Inter-Bold';
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
  }

  .raffle_draw_prize {
    background-image: conic-gradient(
        from -26.52deg at 48.56% 46.69%,
        rgb(209, 184, 96) 0deg,
        rgb(225, 209, 124) 31.84deg,
        rgb(254, 255, 175) 61.18deg,
        rgb(254, 255, 175) 78.99deg,
        rgb(177, 138, 51) 111.56deg,
        rgb(249, 236, 146) 171.3deg,
        rgb(255, 244, 154) 210.88deg,
        rgb(187, 146, 49) 242.64deg,
        rgb(172, 125, 27) 261.9deg,
        rgb(254, 255, 175) 293.47deg,
        rgb(240, 233, 151) 339.07deg,
        rgb(202, 174, 86) 360deg
      ),
      linear-gradient(0deg, rgba(255, 250, 220, 0.2), rgba(255, 250, 220, 0.2));
    color: transparent;
    background-clip: text;
    font-size: 32px;
    font-family: 'Inter-Bold';
    margin-bottom: 12px;
  }

  .countdown_wrapper {
    @include center;
    flex-direction: column;

    .countdown_wrapper_text {
      font-size: 12px;
    }
  }
}

// MOBILE
.mobile {
  .raffle_draw_info_content {
    width: 90%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba($color: #000000, $alpha: 0.8);
    padding: 24px 8px;
  }
}
