@import '../../../../../../../styles/sizes.scss';
@import '../../../../../../../styles/mixins.scss';
@import '../../../../../../../styles/constants';

.history {
  height: 100%;
}

.transaction_history_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  .filters_wrapper {
    width: 100%;
    display: flex;
    flex-shrink: 0;

    .first_section {
      display: flex;
      margin-bottom: 10px;
    }

    .rangepicker {
      display: flex;
      .react-datepicker-wrapper {
        width: 152px;
        svg {
          right: 0;
          top: 5px;
          // color must be a same as text
          fill: var(--text-primary);
          font-size: 14px;
          padding-right: 4px;
        }
        .react-datepicker__input-container {
          width: 94%;
        }
      }

      .div {
        width: 100%;
      }

      .picker {
        border: 1px solid var(--border-primary);
        border-radius: 10px;
        height: 40px;
        background-color: var(--background-surface-2);
        padding: 0 10px;
        color: var(--text-secondary);
        margin: 0 5px;
        font-size: 14px;
        width: 100%;
        text-align: left;
      }
    }

    select {
      @include scrollbar();

      border: 1px solid var(--border-primary);
      border-radius: 10px;
      height: 40px;
      background-color: var(--background-surface-2);
      padding: 0 10px;
      color: var(--text-secondary);
      margin: 0 5px;
    }

    option {
      max-width: 350px;
      min-width: 350px;
      background: var(--background-surface-1);
    }
  }

  .generated_tax_text {
    color: var(--text-secondary);
    font-size: 14px;
    margin-bottom: 10px;
    width: 100%;
    text-align: left;
    flex-shrink: 0;
  }

  .table_wrapper {
    width: 100%;
    margin: 0px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .table_container {
      .entries_table_caption {
        min-height: 44px;
      }
    }

    .loader {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      max-height: $account_modal_height;
      height: 100%;
    }
  }

  .entries_table_caption {
    width: 100%;
    display: flex;
    flex-direction: row;
    background: var(--account-background-surface-2);
    border-radius: 10px;
    padding: 0px 12px;
    height: $login_history_caption_height;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    flex-shrink: 0;

    &__item {
      font-family: Inter-Medium;
      font-size: 16px;
      line-height: 19px;
      color: var(--text-secondary);
      text-transform: capitalize;
      text-align: left;
    }
  }

  .movement {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      width: 17%;
    }

    &:nth-child(5),
    &:nth-child(6) {
      width: 22%;
    }

    text-transform: capitalize;
  }
  .pending-movement {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      width: 10%;
    }

    text-transform: capitalize;
  }

  .gameMovement {
    &:nth-child(1),
    &:nth-child(5) {
      width: 24%;
    }

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(2) {
      width: 13%;
    }

    text-transform: capitalize;
  }

  .gameMovement {
    .table_container__item {
      width: 100%;
    }
  }
  .balanceAfBf {
    p {
      margin: 2px;
    }
  }
  .deviceInfo {
    .tooltip__overlay {
      width: auto !important;
      &--left {
        left: calc(100% - 58px);
        bottom: unset;

        &::before {
          display: none;
        }
      }
    }
  }

  .entries_table_list {
    @include scrollbar();

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-height: 400px; //modal height 700-headerHeight-filtersHeight-tableCaptionsHeight
    overflow-y: auto;
    padding: 5px;
    border-radius: 2px;

    .data_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 7px 12px;
      border: 1px solid var(--border-primary);
      border-radius: 10px;
      margin-bottom: 0.6vh;
      font-size: 12px;

      .detail_container__item {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        white-space: nowrap;
      }

      .cancel-btn {
        background-color: var(--button-primary);
        color: var(--button-primary-text);
        width: 95px;
        height: 30px;
        border-radius: 8px;
      }

      .winLoss_container__item {
        display: flex;
        align-items: center;

        h6 {
          margin: 0;
        }
      }

      &.movement span {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          width: 11%;
        }

        &:nth-child(5) {
          width: 15%;
        }

        &:nth-child(6) {
          width: 30%;
        }

        text-transform: capitalize;
      }

      &.gameMovement span {
        &:nth-child(1),
        &:nth-child(5) {
          width: 24%;
        }

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(2) {
          width: 13%;
        }
        &:nth-child(6) {
          width: 8%;
        }

        text-transform: capitalize;
      }
    }
  }

  .entries_table_pagination {
    width: 100%;
    margin-top: 1vh;
    text-align: center;
    flex-shrink: 0;

    .pagination {
      font-family: Inter-SemiBold;
      font-size: 12px;
      line-height: 15px;
      color: var(--text-primary);
      display: flex;
      justify-content: center;

      .ant-pagination-item {
        a {
          color: var(--text-secondary) !important;
        }

        &.ant-pagination-item-active {
          border-radius: 4px !important;
          color: var(--text-secondary) !important;
          background: var(--background-surface-2) !important;
          height: 32px !important;
          width: 32px !important;
          border: none !important;

          a {
            color: var(--text-secondary) !important;
          }
        }

        a {
          color: var(--text-secondary) !important;
        }
      }

      .ant-pagination-jump-prev,
      .ant-pagination-jump-next {
        display: flex;
        align-items: center;
        .ant-pagination-item-link {
          .ant-pagination-item-container {
            .anticon,
            .ant-pagination-item-ellipsis {
              color: var(--text-primary);
            }
          }
        }
      }

      .ant-pagination-prev,
      .ant-pagination-next {
        .ant-pagination-item-link {
          font-family: Inter-SemiBold !important;
          font-size: 14px !important;
          line-height: 15px !important;
          color: var(--text-secondary) !important;

          .ant-pagination-item-container {
            .ant-pagination-item-ellipsis {
              font-family: Inter-SemiBold !important;
              font-size: 14px !important;
              line-height: 15px !important;
              color: var(--text-secondary) !important;
            }
          }
        }

        &.ant-pagination-disabled {
          .ant-pagination-item-link {
            color: purple !important;
          }
        }
      }
    }
  }
}

.table_container {
  @include scrollbar;

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 100%;
  overflow: auto;
  padding: 0px 10px 0px 0px;

  &__item {
    font-family: Inter-Medium;
    line-height: 17px;
    color: var(--text-secondary);
    text-transform: capitalize;
    text-align: left;
    display: flex;
    align-items: center;

    &__dot {
      background-color: red;
      margin-right: 8px;
      height: 9px;
      width: 9px;
      border-radius: 50%;

      &.pending {
        background-color: var(--warning);
      }

      &.confirmed {
        background-color: var(--success);
      }

      &.rejected {
        background-color: var(--danger);
      }

      &.canceled {
        background-color: var(--secondary);
      }
    }
  }
}

.mobile {
  .transaction_history_wrapper {
    // height: calc(100vh - 59px);
    // height: calc(100svh - 59px);
    padding: 0 12px 12px;

    .movement,
    .gameMovement {
      width: unset;
    }

    .table_wrapper {
      overflow: hidden;
      height: calc(100% - 92px);
      .table_container {
        padding: 0px;

        .entries_table_caption {
          display: none;
        }

        .entries_table_list {
          display: flex;
          min-height: unset;
          max-height: unset;

          .list {
            height: 174px;
            border: 1px solid var(--border-primary);
            border-radius: 10px;
            width: 100%;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .entries_table_caption {
              flex-direction: column;
              display: flex;
              background-color: transparent;
              padding: 12px;
              width: 30%;
              height: 100%;
              align-items: unset;
              margin: 0;

              &__item {
                font-size: 12px;
                font-family: Inter-Medium;
              }

              &.movement,
              &.gameMovement {
                font-family: Inter-Medium;
                font-size: 12px;
                line-height: 15px;
                color: var(--text-secondary);
                width: unset;
              }
            }

            .data_row {
              width: 70%;
              flex-direction: column;
              border: unset;
              padding: 12px;
              height: 100%;
              color: var(--text-secondary);
              align-items: flex-end;
              margin: 0;

              &.movement span,
              &.gameMovement span {
                font-family: Inter-Medium;
                font-size: 12px;
                line-height: 15px;
                color: var(--text-secondary);
                width: unset;
                text-align: end;
              }
              .tooltip__overlay {
                text-align: left !important;
              }
            }
          }
        }
      }
    }

    .filters_wrapper {
      flex-wrap: wrap;

      :global {
        :where(.css-dev-only-do-not-override-26rdvq).ant-picker-range {
          width: unset !important;
        }
      }

      .first_section {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      select {
        width: 48%;
        margin-bottom: 12px;
      }
    }
    .rangepicker {
      position: relative;
      justify-content: center;
      width: 100%;
      .react-datepicker-wrapper {
        width: 35%;
      }

      div {
        .ant-picker {
          top: 40 !important;
        }
      }
    }
  }
}

.tablet {
  .transaction_history_wrapper {
    padding: 0 12px 12px;

    .movement,
    .gameMovement {
      width: unset;
    }

    .table_wrapper {
      height: calc(100% - 92px);
      .table_container {
        padding: 0px;

        .entries_table_caption {
          display: none;
        }

        .entries_table_list {
          display: flex;
          min-height: unset;
          max-height: unset;

          .list {
            height: 126px;
            border: 1px solid var(--border-primary);
            border-radius: 10px;
            width: 100%;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .entries_table_caption {
              flex-direction: column;
              display: flex;
              background-color: transparent;
              padding: 12px;
              width: 30%;
              height: 100%;
              align-items: unset;
              margin: 0;

              &__item {
                font-size: 12px;
                font-family: Inter-Medium;
              }

              &.movement,
              &.gameMovement {
                font-family: Inter-Medium;
                font-size: 12px;
                line-height: 15px;
                color: var(--text-secondary);
                width: unset;
              }
            }

            .data_row {
              width: 70%;
              flex-direction: column;
              border: unset;
              padding: 12px;
              height: 100%;
              color: var(--text-secondary);
              align-items: flex-end;
              margin: 0;

              &.movement span,
              &.gameMovement span {
                font-family: Inter-Medium;
                font-size: 12px;
                line-height: 15px;
                color: var(--text-secondary);
                width: unset;
                text-align: end;
              }
            }
          }
        }
      }
    }

    .filters_wrapper {
      flex-wrap: wrap;

      :global {
        :where(.css-dev-only-do-not-override-26rdvq).ant-picker-range {
          width: unset !important;
        }
      }

      .first_section {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      select {
        width: 48%;
        margin-bottom: 12px;
      }
    }
  }

  .rangepicker {
    position: relative;
    justify-content: center;

    div {
      .ant-picker {
        top: 40 !important;
      }
    }
  }
}

.p {
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-radius: 10px !important;
  height: 40px !important;
  width: 350px !important;
  max-width: 350px !important;
  background-color: var(--background-surface-3) !important;
  padding: 0 10px !important;
  color: var(--text-secondary) !important;
}

:global {
  :where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown {
    z-index: $history__ant-picker-dropdown--index !important;
  }

  :where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown
    .ant-picker-panel-container
    .ant-picker-panels:last-child
    .ant-picker-panel {
    background-color: var(--background-surface-3) !important;
  }

  :where(.css-dev-only-do-not-override-26rdvq).ant-space-vertical {
    margin: 0 5px !important;
  }

  :where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown .ant-picker-header-view button,
  :where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown .ant-picker-date-panel .ant-picker-content th {
    color: var(--text-secondary) !important;
  }

  :where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown .ant-picker-content th,
  :where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown .ant-picker-content td {
    color: var(--text-secondary) !important;
  }

  :where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown .ant-picker-header-view {
    display: flex !important;
  }

  :where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown .ant-picker-header > button {
    color: var(--green) !important;
  }

  :where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown
    .ant-picker-cell-in-view:is(:where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown .ant-picker-cell-today)
    .ant-picker-cell-inner {
    ::before {
      border: 1px solid var(--green) !important;
      border-radius: 50% !important;
    }
  }

  :where(.css-dev-only-do-not-override-26rdvq).ant-picker .ant-picker-clear {
    background: var(--background-surface-3) !important;
    color: var(--text-secondary) !important;

    &:hover {
      background: var(--background-surface-3) !important;
      color: var(--text-secondary) !important;
    }
  }

  :where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown
    .ant-picker-cell-in-view:is(:where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown
      .ant-picker-cell-range-start):not(:where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown
      .ant-picker-cell-range-start-single):not(:where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown
      .ant-picker-cell-range-end)
    .ant-picker-cell-inner {
    border-radius: 50% !important;
    background: #34612e !important;
    border: 1px solid #5aac5e !important;
    display: flex !important;
    width: 75% !important;
    right: -6px !important;
    justify-content: center !important;
    height: 100% !important;
  }

  :where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown
    .ant-picker-cell-in-view:is(:where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown
      .ant-picker-cell-selected)
    .ant-picker-cell-inner,
  :where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown
    .ant-picker-cell-in-view:is(:where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown
      .ant-picker-cell-range-start)
    .ant-picker-cell-inner,
  :where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown
    .ant-picker-cell-in-view:is(:where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown
      .ant-picker-cell-range-end)
    .ant-picker-cell-inner {
    border-radius: 50% !important;
    border: 1px solid #5aac5e !important;
    background: #34612e !important;
    border: none !important;
    width: 26px !important;
    display: block !important;
    margin: 0 auto !important;

    &:hover {
      background: #34612e !important;
    }
  }

  :where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown
    .ant-picker-cell-in-view:is(:where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown .ant-picker-cell-today)
    .ant-picker-cell-inner::before {
    border: none !important;
  }

  :where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown
    .ant-picker-cell-in-view:is(:where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown
      .ant-picker-cell-in-range)::before,
  :where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown
    .ant-picker-cell-in-view:is(:where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown
      .ant-picker-cell-range-start):not(:where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown
      .ant-picker-cell-range-start-single)::before,
  :where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown
    .ant-picker-cell-in-view:is(:where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown
      .ant-picker-cell-range-end):not(:where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown
      .ant-picker-cell-range-end-single)::before {
    background-color: rgba(125, 147, 121, 0.3) !important;
  }

  :where(.css-dev-only-do-not-override-26rdvq).ant-picker-range {
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    border-radius: 10px !important;
    height: 40px !important;
    width: 350px !important;
    max-width: 350px !important;
    background-color: var(--background-surface-3) !important;
    padding: 0 10px !important;
    color: var(--text-secondary) !important;
  }

  :where(.css-dev-only-do-not-override-26rdvq).ant-btn-primary:disabled {
    color: var(--text-secondary) !important;
  }

  :where(.css-dev-only-do-not-override-26rdvq).ant-picker .ant-picker-input > input {
    &::placeholder {
      color: var(--text-secondary) !important;
    }
  }

  :where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown .ant-picker-header-view {
    justify-content: center !important;
  }

  :where(.css-dev-only-do-not-override-26rdvq).ant-picker .ant-picker-separator,
  :where(.css-dev-only-do-not-override-26rdvq).ant-picker .ant-picker-suffix,
  :where(.css-dev-only-do-not-override-26rdvq).ant-picker .ant-picker-input > input {
    color: var(--text-secondary) !important;
  }

  :where(.css-dev-only-do-not-override-26rdvq).ant-picker:hover,
  :where(.css-dev-only-do-not-override-26rdvq).ant-picker-focused {
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
  }

  :where(.css-dev-only-do-not-override-26rdvq).ant-picker-range .ant-picker-active-bar,
  :where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown .ant-picker-datetime-panel .ant-picker-time-panel {
    display: none !important;
  }

  .ant-pagination-item {
    &.ant-pagination-item-active {
      border-radius: 4px !important;
      background: var(--background-surface-3) !important;
      height: 32px !important;
      width: 32px !important;
      border: none !important;
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      font-family: Inter-SemiBold !important;
      font-size: 14px !important;
      line-height: 15px !important;
      color: var(--text-secondary) !important;

      .ant-pagination-item-container {
        .ant-pagination-item-ellipsis {
          font-family: Inter-SemiBold !important;
          font-size: 14px !important;
          line-height: 15px !important;
          color: var(--text-secondary) !important;
        }
      }
    }

    &.ant-pagination-disabled {
      .ant-pagination-item-link {
        color: var(--text-primary) !important;
      }
    }
  }

  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    .ant-pagination-item-link {
      .ant-pagination-item-container {
        .ant-pagination-item-ellipsis,
        .anticon {
          color: var(--text-secondary) !important;
          font-size: 10px !important;
        }
      }
    }
  }

  :where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown .ant-picker-panel-container,
  :where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown .ant-picker-range-arrow::before,
  :where(.css-dev-only-do-not-override-26rdvq).ant-btn-primary {
    background-color: var(--gray-dark) !important;
  }
}

.ant-picker-dropdown .ant-picker-panel-container {
  background-color: var(--background-surface-2);

  .ant-picker-header-view {
    display: flex;
    width: 100%;
    color: var(--text-secondary);
    justify-content: center;
  }

  .ant-picker-header-super-prev-btn,
  .ant-picker-header-prev-btn,
  .ant-picker-header-next-btn,
  .ant-picker-header-super-next-btn {
    color: var(--text-secondary);
  }

  .ant-picker-content th,
  .ant-picker-cell-in-view {
    color: var(--text-secondary);

    &:hover {
      background-color: var(--primary-5);
    }
  }

  .ant-picker-cell-today {
    .ant-picker-cell-inner::before {
      border: 1px solid var(--primary-5) !important;
    }
  }

  .ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: var(--primary-5);
  }

  .ant-picker-footer {
    display: none;
  }
}

.ant-picker-cell-selected {
  .ant-picker-cell-inner {
    background-color: var(--primary-5) !important;
  }
}

@media screen and (max-width: 375px) {
  .entries_table_pagination {
    .pagination {
      .ant-pagination-item {
        min-width: 16px;
      }
    }
  }
}

.empty {
  color: var(--text-primary);
}
