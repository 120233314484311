@import '../../styles/sizes.scss';
@import '../../styles/mixins.scss';
@import '../../styles/constants';

.no_data_container {
  height: 100%;
  width: 100%;
  @include center();
  h3 {
    color: var(--text-secondary);
  }
}

.loader_container {
  height: 100%;
  width: 100%;
  @include center();
  position: relative;
  bottom: 25px;
  // Account modal height - header and paddings
 // min-height: 70vh;
}

.no_data_container {
  height: 100%;
  width: 100%;
  @include center();

  h3 {
    color: var(--text-secondary);
  }
}

.desktop {
  .payment_container {
    .payment_inner_content {
      .payment {
        .input_container {
          position: relative;
          .grouped_content {
            &.payment_bottom_content {
              height: calc(100% - 173px);
              overflow-y: auto;
              padding: 10px;
              @include scrollbar();
            }
          }
          .payment_submit_button {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

.payment_inner_content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;

  .tabs_section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &__title {
      font-family: Inter-SemiBold;
      font-size: 16px;
      line-height: 19px;
      color: var(--text-primary);
    }

    &__items {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  .payment {
    @include scrollbar();

    overflow: auto;
    border-radius: 8px;
    padding: 10px 0 24px;
    display: flex;
    width: 100%;
    height: 100%;

    .input_container {
      width: calc(100% - 148px);

      .tabs_section__items {
        .tabs_section__item_1 {
          margin-right: 5px;
        }

        &.grouped {
          background-color: var(--account-background-surface-2);
          padding: 10px;
          border-radius: 8px;
          margin-bottom: 20px;
        }
      }
    }

    .grouped_content,
    .payment_bottom_content {
      padding: 0;
      .payment-iframe {
        @include scrollbar();
        height: 70vh;
        overflow-y: scroll;
      }
    }

    .grouped_content {
      background-color: var(--account-background-surface-2);
      border-radius: 8px;
    }

    &__information {
      width: fit-content;
      min-height: $account_modal_payment_information_height;
      padding: 8px 13px;
      background: var(--account-background-surface-2);
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 15px 0;
    }

    &__pament_rules {
      margin-bottom: 24px;
      font-family: Inter-Medium;
      font-size: 14px;
      line-height: 17px;
      color: #bbbbbd;
    }

    .payment_content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      input {
        border: 1px solid var(--input-border-default);
        background-color: var(--account-background-surface-2);

        &:focus {
          background-color: var(--account-background-surface-3);
          border: 1px solid var(--input-border-typing);
        }
      }
    }

    .payment_submit_button {
      height: $btn-lg-height;
      max-width: 100%;
      width: inherit;
      margin-top: 30px;

      button {
        height: $btn-lg-height;
        max-width: inherit;
        width: inherit;
        border-radius: 8px;
      }

      .success-filled-btn {
        &.disabled {
          background: var(--account-background-surface-3);
          color: var(--text-primary);
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .payment_inner_content {
    .payment {
      .payment_submit_button {
        button {
          width: 350px;
        }
      }
    }
  }
}

.notif {
  min-height: 150px;

  .notification {
    height: 25px;
  }

  p {
    text-align: center;
    margin: 10px 0 0;
    color: var(--text-secondary) !important;
  }

  svg {
    // z-index: $payment-view__notif-svg--index;
    z-index: $icon-index;
    position: absolute;
    height: 20px;
    top: 8px;
  }

  .btn-wrapper {
    background-color: var(--account-background-surface-1);
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;

    button {
      width: 100%;
      height: $btn-lg-height;
    }
  }

  &.error_notification_class {
    border: 2px solid var(--button-brand);
    height: fit-content;
    width: 350px;
    flex-direction: column;
    @include center();

    p {
      font-size: 18px;
      margin-bottom: 30px;
    }

    button {
      border-radius: 8px;
      width: 100px;
      height: 43px;
    }
  }

  &.success_notification_class {
    height: fit-content;
    border: 2px solid green;
    padding: 20px;
    width: 350px;
    flex-direction: column;
    @include center();

    &.qr_code_container {
      width: 420px;

      .qr_code_content {
        padding: 0 20px;
        width: inherit;
        display: flex;
        flex-direction: column;
        justify-content: center;

        img {
          width: 40%;
          display: flex;
          background: white;
          margin: 0 auto;
        }

        p {
          width: initial;
          word-break: break-all;
          font-size: 13px;
          font-family: Inter-Medium;
          margin: 10px 0;
        }
      }

      .btn-wrapper {
        flex-direction: column;

        button {
          width: 100%;
          margin: 0 0 10px;
        }

        .success-filled-btn {
          text-transform: capitalize !important;
        }
      }
      .exchange_rate {
        text-align: center;
        span {
          margin-right: 4px;
        }
        p {
          margin-bottom: 0;
          margin-top: 0;
          font-size: 14px;
        }
      }
    }

    p {
      font-size: 18px;
      margin-bottom: 30px;
      color: var(--text-secondary) !important;
    }

    button {
      background-color: green;
      color: white;
      border-radius: 8px;
      width: 100px;
      height: 43px;
    }

    svg path {
      fill: green;
    }
  }
}

.mobile {
  .payment .payment--grouped-payments {
    position: absolute;
    right: 0;
    left: 0;
    width: 100%;
  }

  .payment_inner_content {
    padding: 0 10px 10px 21px;
    margin-top: 0;

    .tabs_section {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;

      .back {
        transform: rotate(90deg);
      }
    }

    .input_container {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      @include scrollbar();
      position: relative;

      input {
        width: 100% !important;
        max-width: unset;
      }

      &.show {
        padding: 15px;
        overflow: auto;
        max-height: 72vh;
      }
      .payment-iframe {
        height: 80vh;
      }
    }

    .tabs_section__items {
      justify-content: flex-start;
      width: 100%;
    }

    .pament_info_section {
      height: unset;
      padding: 15px;

      &__information {
        width: unset;
        justify-content: space-between;
      }
    }

    .payment_submit_button {
      width: 100% !important;
      margin: 10px auto 30px;
      max-width: unset;

      button {
        width: 100% !important;
        margin: 0;
        max-width: unset;
      }

      .success-filled-btn {
        &.disabled {
          background: var(--account-background-surface-3);
          color: var(--text-primary);
        }
      }
    }

    .payment_content div {
      width: 100% !important;
      text-align: initial !important;

      input,
      select {
        width: inherit !important;
      }
    }
  }
  .notif.success_notification_class.qr_code_container {
    .exchange_rate {
      p {
        font-size: 12.5px;
      }
    }
  }
 .phone_example {
   color: var(--text-secondary);
   text-align: left;
 }
}

@media (max-width: 480px) {
  .notif {
    &.success_notification_class {
      &.qr_code_container {
        width: 95vw;

        .qr_code_content {
          p {
            color: white;
          }
        }
      }
    }
  }

  .payment {
    overflow: auto;
  }
}

.dialog-template {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  inset: 0;

  &__overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(1, 1, 1, 0.5);
    z-index: 0;
    pointer-events: none;
  }

  &__inner {
    width: 497px;
    background: #1b1d24;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    padding-top: 40px;
    position: relative;
    text-align: center;

    &.mobile:not(.landscape) {
      width: 90%;
      padding-top: 36px;

      .dialog-template__body {
        margin-top: 12px;
      }

      .dialog-template__close-wrapper {
        top: 8px;
        right: 8px;
      }

      .dialog-template__title {
        font-size: 22px;
      }

      .dialog-template__text {
        margin-top: 9px;
        font-size: 16px;
        padding: 0 13px;
      }

      .dialog-template__buttons-wrapper {
        padding: 18px 20px;

        button {
          height: 40px;
        }
      }
    }
  }

  &__close-wrapper {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #313341;
    @include center();
    cursor: pointer;
  }

  &__close {
    color: #c2c2c2;
    font-size: 16px;
  }

  &__title {
    font-family: Inter-Bold;
    font-size: 26px;
    line-height: 1.2;
    color: #ffffff;
    width: 86%;
    display: inline-block;
  }

  &__text {
    padding: 0 45px;
    font-family: Inter-Medium;
    font-size: 18px;
    line-height: 1.18;
    color: #f0f0e6;
    margin-bottom: 0;
    margin-top: 12px;

    span {
      color: #ffbf17;

      &.underline {
        text-decoration: underline;
      }
    }
  }

  &__body {
    // background: #14161c;
    border-radius: 0 0 8px 8px;
    margin-top: 20px;
  }

  &__buttons-wrapper {
    padding: 24px 20px;

    button {
      height: 51px;
      width: 100%;
      border-radius: 8px;
      font-family: Inter-Bold;
      font-size: 16px;
      line-height: 1.18;
      border: none;
      outline: none;
      text-transform: uppercase;
      transition: 0.35s ease-out;
    }
  }
  .description-wrapper {
    .logo {
      position: absolute;
      top: 12px;
    }
  }
}

.payment_container {
  max-height: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  width: 100%;
  z-index: $payment-view__payment-container--index;
  position: relative;

  .payment_content {
    width: 100%;
  }

  .payment_inner_content {
    .group_item {
      justify-content: space-between;
      cursor: pointer;
      display: flex;

      &:hover {
        opacity: 0.6;
        border: 1px solid var(--bg-main-color-7);
      }

      &.active {
        background-color: var(--account-background-surface-3);
        border: 0.5px solid var(--border-primary-selected);
      }
    }
  }

  .group_container {
    background-color: var(--account-background-surface-2);
    border-radius: 8px;
    width: 208px;
    padding: 20px 12px 10px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 560px;
    margin-right: 15px;
    height: 100%;

    @include scrollbar();

    .group_item {
      width: 124px;
      height: 53px;
      background-color: var(--account-background-surface-3);
      border-radius: 8px;
      margin: 5px 0;
      display: flex;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
        border: 1px solid var(--bg-main-color-7);
      }

      &.active {
        background-color: var(--account-background-surface-3);
      }
    }
  }
}

.group_item {
  .active_container {
    .active {
      position: relative;
      top: 28px;
      right: 5px;
    }
  }
}

@media (max-width: 480px) {
  .group_item {
    align-items: center;
    padding: 0 10px;
    border-radius: 0;
    margin: 0;
    width: 100%;
    height: 47px;
    position: relative;

    .group_image {
      border-radius: 4px;
      position: absolute;
      // background-color: var(--group-payment-bg);
    }

    .bottom_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 25px;

      .active_icon {
        margin-right: 6px;
      }
    }

    img {
      height: 27px;
    }

    .active_container {
      position: absolute;
      right: 85px;

      span {
        background-color: var(--account-background-surface-2);
        padding: 5px 10px;
        gap: 10px;
        border-radius: 20px;
        backdrop-filter: blur(2px);
        color: var(--text-secondary);
        font-size: 10px;
        font-style: normal;
        font-family: Inter-Medium;
        line-height: normal;
      }
    }

    .active_icon {
      position: absolute;
      height: 15px;
      right: 30px;
    }
  }

  .payment_container {
    overflow: hidden;

    .payment_inner_content {
      padding: 0;

      .payment {
        padding: 0;
      }
    }

    .tabs_section {
      padding: 10px 20px 20px;

      .back {
        position: absolute;
        left: -2px;
      }
    }

    .group_container {
      background-color: var(--account-background-surface-1);
      width: 100vw;
      margin: 0;
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
      z-index: $payment-view__mobile-payment-container-group-container--index;
      height: calc(100vh - 110px);
      height: calc(100svh - 110px);
      padding: 17px 0 50px;

      .inner_container {
        max-height: 100%;
        width: 91vw;
        display: inline-block;
      }

      @include scrollbar();

      .group_item {
        width: 100%;
        height: 53px;
        background-color: var(--account-background-surface-3);
        border-radius: 8px;
        margin: 5px 0;
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        &:hover {
          opacity: 0.6;
          border: 1px solid var(--bg-main-color-7);
        }

        &.active {
          background-color: var(--account-background-surface-3);
        }

        &::after {
          content: '';
          position: absolute;
          right: 8px;
          top: 28px;
          display: block;
          border-right: 1px solid var(--text-secondary);
          border-bottom: 1px solid var(--text-secondary);
          width: 8px;
          height: 8px;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }

    .hide {
      display: none;
    }

    .input_container {
      overflow: auto;
      padding: 0 15px;

      .payment_information_item__name {
        color: var(--text-primary);
        text-align: initial;
        font-size: 12px;
        background: var(--account-background-surface-2);
        border-radius: 8px;
        padding: 10px;
        margin: 0 0 8px;
      }

      .payment__pament_rules {
        margin: 10px 0 0;
      }

      .payment_template_2 {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        width: 100%;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
          display: none;
        }

        .tabs_section__item_2 {
          flex: 0 0 auto;
          margin-right: 3px;
        }
      }

      &.show {
        display: block;
        padding: 0 12px;
      }

      .tabs_section__items {
        justify-content: flex-start;
        flex: 0 0 auto;

        &.grouped {
          background-color: unset;
          padding: 0 0 10px;
        }
      }
    }
  }
}

@media (min-width: 481px) and (max-width: 1023px) {
  .payment_container {
    .payment_inner_content {
      .group_item {
        width: 95%;
        margin: 0 10px 10px;
        border-radius: 8px;

        img {
          width: 80px;
          position: relative;
          top: 8px;
          height: 30px;
        }
      }
      .payment {
        .payment_submit_button {
          button {
            width: 350px;
          }
        }
      }
    }
  }

  .payment_inner_content .payment .input_container{
    width: 100%;

    .tabs_section__item_1{
      margin: 5px;
    }
  }
}

@media (min-width: 601px) {
  .payment_container {
    .payment_inner_content {
      .group_item {
        width: 100%;
        margin: 0 0 10px;
        display: flex;
        flex-direction: column;
        height: 96px;
        justify-content: space-evenly;

        .bottom_container {
          display: flex;
          justify-content: space-between;
          align-items: end;
          height: 25px;
          gap: 10px;
          padding: 3px 10px;
          border-radius: 20px;
          background-color: var(--account-background-surface-2);
          backdrop-filter: blur(2px);
          margin-top: 7px;
          margin-left: 7px;
          width: fit-content;

          .active_icon {
            margin-right: 6px;
          }
        }

        .group_image {
          width: 146px;
          height: 73px;
          // background-color: var(--group-payment-bg);
          border-radius: 8px;
          margin: 0 auto;

          svg {
            height: 20px;
          }
        }

        .active_container {
          padding: 0 10px;

          span {
            color: var(--text-primary);
            font-size: 10px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
