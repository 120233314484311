@import '../../../styles/mixins.scss';

.responsible_gaming_container {
  grid-area: responsible_gaming;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;

  .responsible_gaming_item {
    cursor: pointer;
    user-select: none;
    color: var(--text-primary);
    text-transform: uppercase;
    font-style: normal;
    font-family: Inter-SemiBold;
    font-size: 14px;
    text-decoration: underline;
  }
}

// MOBILE
.mobile {
  .responsible_gaming_container {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
}
