@import '../../../styles/mixins.scss';

.accordion_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.accordion_title {
  text-transform: capitalize;
  color: var(--text-brand);
  margin-bottom: 12px;
}

.accordion {
  width: 100%;
  background-color: var(--background-surface-2);
}

.accordion_item {
  border-bottom: 1px solid var(--border-primary);
  cursor: pointer;
}

.accordion_item__header {
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.toggle_button {
  @include center;
  margin-left: 20px;
  background: var(--button-secondary);
  color: var(----button-secondary-test);
  border: none;
  outline: none;
  font-size: 16px;
  font-family: 'Inter-Bold';
  height: 25px;
  width: 25px;
  border-radius: 8px;

  &_icon {
    transition: transform 0.3s;
  }

  &.toggle_button__active {
    .toggle_button_icon {
      transform: rotate(90deg);
    }
  }
}

.accordion_item__body {
  overflow: hidden;
  transition: height 0.3s ease, padding 0.3s ease;

  .body_description {
    font-size: 14px;
    padding: 0px 16px 16px;
    color: rgba(255, 255, 255, 0.7);
  }
}

// Tablet
.tablet {
  .accordion_item__header {
    .header_title {
      font-size: 16px;
    }
  }
}

// Mobile
.mobile {
  .accordion_title {
    font-size: 16px;
    margin-bottom: 8px;
  }
}
