@import '../styles/sizes.scss';
@import '../configs/medias.scss';

.page_container {
  margin: 0 auto;
}

.jackpot_title {
  font-style: normal;
  font-family: Inter-Bold;
  font-size: 20px;
  line-height: 24px;
  overflow: hidden;
  text-align: inherit;
  margin-bottom: 15px;
  color: var(--text-secondary);
}
.egtd_jpw_hover_popup {
  z-index: 10000000000000;
}

.jackpots {
  display: flex;
  flex-direction: column;
  .jackpot_elem {
    margin: 5px;
    cursor: pointer;
  }
}

.mobile {
  .jackpots {
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 10px;
    .egtd_jpw_hover_popup {
      display: none;
    }
    .jackpot_elem {
      width: 200px;
      height: 262px;
      & > div {
        width: 200px;
        height: 262px;
      }
    }
  }
  .page_container {
    &_second_section {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: space-evenly;
    }

    &_third_section {
      margin-top: 10px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
