.entries_table_pagination {
  margin: 10px auto;
  width: 200px;
  .pagination {
    .ant-pagination-item.ant-pagination-item {
      display: inline-block;
      min-width: 32px;
      height: 32px;
      margin-inline-end: 8px;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
      line-height: 30px;
      text-align: center;
      vertical-align: middle;
      list-style: none;
      background-color: transparent;
      border: 1px solid transparent;
      border-radius: 6px;
      outline: 0;
      cursor: pointer;
      user-select: none;
      a {
        color: var(--text-secondary) !important;
      }
    }
    .ant-pagination-item.ant-pagination-item-active {
      background: var(--background-surface-2) !important;
      border: none !important;
      border-radius: 4px !important;
      color: var(--text-secondary) !important;
      height: 32px !important;
      width: 32px !important;
      a {
        color: var(--text-secondary) !important;
      }
    }
    .ant-pagination-item-link {
      color: var(--text-secondary) !important;
      font-family: Inter-SemiBold !important;
      font-size: 14px !important;
      line-height: 15px !important;
    }
  }
}

@media screen and (max-width: 375px) {
  .entries_table_pagination {
    .pagination {
      .ant-pagination-item.ant-pagination-item {
        min-width: 16px;
      }
    }
  }
}
