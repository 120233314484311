.inactivity-modal-container {
  background-color: var(--background-surface-1);
  margin-top: 10%;
  padding: 0 !important;
  border-radius: 8px;
  overflow: hidden;

  .ant-modal-close {
    background: var(--background-surface-3);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    span {
      color: var(--text-secondary);
      font-size: 8px;
      line-height: unset;
    }
  }
  .ant-modal-content {
    padding: 0;
    box-shadow: none;
    border-radius: 8px;
  }

  h6 {
    font-family: Inter-Medium;
    font-size: 16px;
    line-height: 19px;
    color: var(--text-primary);
  }
  button {
    h6 {
      color: var(--button-primary-text);
    }
  }

  &__title {
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
    h6 {
      margin-right: 8px;
      font-family: Inter-Bold;
      font-size: 24px;
      line-height: 29px;
    }
  }

  &__counter {
    color: var(--text-brand);
    border: 1px solid var(--border-primary);
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 5px 12px;
    margin-top: 20px;
    h6 {
      color: var(--text-brand);
      margin-left: 8px;
    }
  }
  .ant-modal-body {
    padding: 40px 0px 0px;
    background-color: var(--background-surface-2);
    .inactivity-modal__button-wrapper {
      background-color: var(--background-surface-1);
      margin: 16px 0 0;
      padding: 24px 20px;
      width: 100%;

      button {
        width: 100%;
        height: 51px;
        border-radius: 8px;
        text-transform: uppercase;
        font-family: Inter-Bold;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        margin: 0px;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
