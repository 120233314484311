@import '../sizes.scss';
@import '../constants';
@import '../mixins.scss';

.desktop {
  &.template_noFullScreen {
    display: block;
    margin: 0 auto;

    .header-3Rows-with_nl {
      .home_container,
      .page_container {
        padding: 15px 0;
      }

      .home_container,
      .page_container,
      .content_container,
      .promotions__container {
        margin: calc($headerTopLineHeight-3Row + $headerMiddleLineHeight-3Rows + $headerBottomLineHeight-3Rows) auto 0;
        display: block;
        border-radius: 8px;
      }

      .casinoTournament,
      .race,
      .staticIframe,
      .dinhutech,
      .betting,
      .liveSport,
      .cybersport,
      .betting,
      .tombala,
      .poker,
      .silvaSeries,
      .fun-mission {
        .frame {
          visibility: visible;
          height: calc(
            100vh - $headerTopLineHeight-3Row - $headerMiddleLineHeight-3Rows - $headerBottomLineHeight-3Rows - 10px
          );
          max-width: $maxWidth;
          border-radius: 8px;
          margin: calc($headerTopLineHeight-3Row + $headerMiddleLineHeight-3Rows + $headerBottomLineHeight-3Rows) auto 0;
          display: block;

          &.fullScreen {
            width: 100vw;
            height: 100vh;
            height: 100svh;
            max-width: unset;
            margin-top: 0;
          }
        }
      }
    }

    .header-2Rows-default-noFullScreen {
      .home_container,
      .page_container,
      .content_container,
      .promotions__container {
        margin: calc($headerTopLineHeight-2Rows + $headerBottomLineHeight-2Rows) auto 20px;
        height: 100%;
      }

      .footer_outer_container,
      .footer_with_btn {
        max-width: $maxWidth;
        border-radius: 8px;
        margin: 0 auto;
      }

      .casinoTournament #tournament,
      .race #raceIframe,
      .staticIframe #staticIframe,
      .dinhutech #staticIframe,
      .betting #sport_frame,
      .betting #sport,
      .liveSport #sport,
      .cybersport #cybersport,
      .tombala #tombala,
      .poker #pokerIframe,
      .silvaSeries #staticIframe,
      .blog #blogIframe,
      .fun-mission #funMissionIframe {
        visibility: visible;
        height: calc(100vh - $headerTopLineHeight-2Rows - $headerBottomLineHeight-2Rows - 10px);
        margin: calc($headerTopLineHeight-2Rows + $headerBottomLineHeight-2Rows) auto 0;
      }
      .casinoTournament #tournament.fullScreen {
        height: 100vh;
        height: 100svh;
        margin-top: 0;
        max-width: none;
        width: 100vw;
      }

      .footer_outer_container {
        max-width: $maxWidth;
        margin: 0 auto;
      }

      .footer_with_btn {
        max-width: calc($maxWidth + 10px);
        margin: 0 auto;
      }
    }

    .header-2Rows-with-nl {
      .home_container,
      .page_container,
      .content_container .promotions__container {
        margin: calc($headerTopLineHeight-2Rows + $headerBottomLineHeight-2Rows) auto 0;
      }
    }

    .header-1Row {
      .home_container,
      .page_container,
      .promotions__container {
        margin: $headerTopLineHeight-1Row auto 0;
      }
    }

    .page_container,
    .home_container,
    .content_container {
      position: relative;
      right: -4px;
    }

    .desktop_header,
    .home_container,
    .page_container,
    .content_container,
    .promotions__container,
    .footer_horizontal_default,
    .footer_vertical_default,
    .footer_outer_container,
    .footer_with_btn {
      max-width: $maxWidth;
      margin: 0 auto;

      .navigation-container {
        border-radius: 8px;
      }
    }
    .is-load {
      right: 0;
    }
  }
}

.tablet {
  &.template_noFullScreen {
    display: block;
    margin: 0 auto;
    overflow-x: hidden;

    .header-3Rows-with_nl {
      .home_container,
      .page_container {
        padding: 15px 0;
      }

      .home_container,
      .page_container,
      .promotions__container,
      .casinoTournament #tournament,
      .race #raceIframe,
      .staticIframe #staticIframe,
      .dinhutech #staticIframe,
      .betting #sport_frame,
      .liveSport #sport,
      .cybersport #cybersport,
      .betting #sport,
      .tombala #tombala,
      .poker #pokerIframe,
      .fun-mission #funMissionIframe {
        margin: calc($headerTopLineHeight-3Row + $headerMiddleLineHeight-3Rows + $headerBottomLineHeight-3Rows) auto 0;
        display: block;
        border-radius: 8px;
      }

      .casinoTournament #tournament,
      .poker #tournament,
      .race #raceIframe,
      .staticIframe #staticIframe,
      .dinhutech #staticIframe,
      .betting #sport_frame,
      .betting #sport,
      .liveSport #sport,
      .cybersport #cybersport,
      .tombala #tombala,
      .poker #pokerIframe,
      .fun-mission #funMissionIframe {
        height: calc(
          100vh - $headerTopLineHeight-3Row - $headerMiddleLineHeight-3Rows - $headerBottomLineHeight-3Rows - 10px
        );
        max-width: unset;
        border-radius: 8px;

        &.fullScreen {
          width: 100vw;
          height: 100vh;
          height: 100svh;
          max-width: unset;
          margin-top: 0;
        }
      }
    }
  }

  &.header-2Rows-default-noFullScreen {
    .casinoTournament #tournament,
    .race #raceIframe,
    .staticIframe #staticIframe,
    .dinhutech #staticIframe,
    .betting #sport_frame,
    .liveSport #sport,
    .cybersport #cybersport,
    .betting #sport,
    .tombala #tombala,
    .poker #pokerIframe,
    .silvaSeries #staticIframe,
    .fun-mission #funMissionIframe {
      margin: calc($headerTopLineHeight-2Rows + $headerBottomLineHeight-2Rows) auto 0;
      visibility: visible;
      height: calc(100vh - $headerTopLineHeight-2Rows - $headerBottomLineHeight-2Rows);
    }

    .footer_outer_container,
    .footer_with_btn {
      max-width: $maxWidth;
      border-radius: 8px;
      margin: 0 auto;
    }

    .footer_outer_container {
      max-width: $maxWidth;
      margin: 0 auto;
    }

    .footer_with_btn {
      max-width: calc($maxWidth + 10px);
      margin: 0 auto;
    }

    .content_container,
    .promotions__container,
    .page_container,
    .home_container {
      position: unset;
      right: unset;
      margin: calc($headerTopLineHeight-2Rows + $headerBottomLineHeight-2Rows) auto 0;
      display: block;
      width: 100vw;
    }
  }

  .header-2Rows-with-nl {
    .home_container,
    .page_container,
    .promotions__container {
      margin: calc($headerTopLineHeight-2Rows + $headerBottomLineHeight-2Rows) auto 0;
    }
  }

  .header-1Row {
    .home_container,
    .page_container,
    .promotions__container {
      margin: $headerTopLineHeight-1Row auto 0;
    }
  }

  .page_container,
  .home_container {
    position: relative;
    right: -4px;
  }

  .page_container {
    .page_container_second_section {
      padding: 0 24px;
    }
  }

  .desktop_header,
  .home_container,
  .page_container,
  .promotions__container,
  .footer_horizontal_default,
  .footer_vertical_default,
  .footer_outer_container,
  .footer_with_btn {
    max-width: $maxWidth;
    margin: 0 auto;

    .navigation-container {
      border-radius: 8px;
    }
  }
  .is-load {
    right: 0;
  }
}

.mobile {
  &.template_noFullScreen {
    margin: 0 auto $mobileFixedMenuHeight;

    .header-1Row {
      .promotions__container,
      .content_container,
      .home_container,
      .page_container {
        margin: $mobileHeaderTopLineHeight-1Row 0 0;
      }
      .content_container {
        max-width: 99%; // for poker or other content page left-right scroll
      }

      &.casinoTournament #tournament,
      &.race #raceIframe,
      &.staticIframe #staticIframe,
      &.betting #sport_frame,
      &.betting #sport,
      &.liveSport #sport,
      &.cybersport #cybersport,
      &.tombala #tombala,
      &.poker #pokerIframe,
      &.silvaSeries #staticIframe,
      &.blog #blogIframe,
      &.fun-mission #funMissionIframe {
        visibility: visible;
        margin: $mobileHeaderTopLineHeight-1Row 0 $mobileFixedMenuHeight;

        @supports (-webkit-touch-callout: none) {
          height: calc(100vh - $mobileHeaderTopLineHeight-1Row - $mobileFixedMenuHeight);
          height: calc(100svh - $mobileHeaderTopLineHeight-1Row - $mobileFixedMenuHeight);
        }

        @supports not (-webkit-touch-callout: none) {
          height: calc(
            100vh - $mobileHeaderTopLineHeight-1Row - $mobileFixedMenuHeight - 50px
          ); // additional fix for andriod
        }
      }
      @supports (-webkit-overflow-scrolling: touch) {
        &.fun-mission #funMissionIframe {
          margin: $mobileHeaderTopLineHeight-1Row 0 0;
          @supports not (-webkit-touch-callout: none) {
            height: calc(100vh - $mobileHeaderTopLineHeight-1Row - 0px - 50px);
          }
        }
      }
      &.casinoTournament #tournament {
        @supports not (-webkit-touch-callout: none) {
          height: calc(100vh - $mobileHeaderTopLineHeight-1Row - $mobileFixedMenuHeight); // additional fix for andriod
        }
      }
      &.casinoTournament #tournament.fullScreen {
        height: 100vh;
        height: 100svh;
        margin-top: 0;
        max-width: none;
        padding: 0;
        width: 100vw;
      }
    }

    .header-2Rows-with-nl {
      .mobile_header_wrapper > div:last-child {
        justify-content: flex-end;
      }

      .page_container,
      .home_container {
        @supports (-webkit-touch-callout: none) {
          margin: calc($mobileHeaderTopLineHeight-2Row_with_nl + $mobileHeaderBottomLineHeight_2Row_with_nl) 0 0;
        }

        @supports not (-webkit-touch-callout: none) {
          margin: calc($mobileHeaderTopLineHeight-2Row_with_nl + $mobileHeaderBottomLineHeight_2Row_with_nl) 0 0;
        }
      }

      .promotions__container,
      &.casinoTournament #tournament,
      &.race #raceIframe,
      &.staticIframe #staticIframe,
      &.dinhutech #staticIframe,
      &.betting #sport_frame,
      &.betting #sport,
      &.tombala #tombala,
      &.fun-mission #funMissionIframe {
        @supports (-webkit-touch-callout: none) {
          margin: calc($mobileHeaderTopLineHeight-2Row_with_nl + $mobileHeaderBottomLineHeight_2Row_with_nl) 0
            $mobileFixedMenuHeight;
        }

        @supports not (-webkit-touch-callout: none) {
          margin: calc($mobileHeaderTopLineHeight-2Row_with_nl + $mobileHeaderBottomLineHeight_2Row_with_nl) 0
            $mobileFixedMenuHeight;
        }
      }

      &.casinoTournament #tournament,
      &.race #raceIframe,
      &.staticIframe #staticIframe,
      &.betting #sport_frame,
      &.betting #sport,
      &.tombala #tombala,
      &.fun-mission #funMissionIframe {
        width: 100vw;
        visibility: visible;
        @supports (-webkit-touch-callout: none) {
          height: calc(
            100vh - calc($mobileHeaderTopLineHeight-2Row_with_nl + $mobileHeaderBottomLineHeight_2Row_with_nl) -
              $mobileFixedMenuHeight
          );
          height: calc(
            100svh - calc($mobileHeaderTopLineHeight-2Row_with_nl + $mobileHeaderBottomLineHeight_2Row_with_nl) -
              $mobileFixedMenuHeight
          );
        }

        @supports not (-webkit-touch-callout: none) {
          height: calc(
            100vh - calc($mobileHeaderTopLineHeight-2Row_with_nl + $mobileHeaderBottomLineHeight_2Row_with_nl) -
              $mobileFixedMenuHeight - 50px
          ); // additional fix for andriod
        }

        &.fullScreen {
          width: 100vw;
          height: 100vh;
          height: 100svh;
          max-width: unset;
          margin-top: 0;
        }
      }
    }

    .footer {
      .social_networks_container {
        justify-content: flex-start;
      }
    }
  }
  .fixed-to-bottom {
    position: fixed;
    top: 0;
    visibility: visible;
    margin: $mobileHeaderTopLineHeight-1Row 0;
    height: calc(100vh - $mobileHeaderTopLineHeight-1Row) !important;
    height: calc(100svh - $mobileHeaderTopLineHeight-1Row) !important;

    @supports not (-webkit-touch-callout: none) {
      height: calc(100vh - $mobileHeaderTopLineHeight-1Row - 50px) !important;
    }
  }
}
@supports (-webkit-overflow-scrolling: touch) {
  .fun-mission.mobile {
    &.template_noFullScreen {
      margin: 0 auto 0;
    }
  }
}

.template_noFullScreen {
  .primary-filled-btn {
    background-color: var(--button-brand);
    color: var(--button-brand-text);

    svg path {
      fill: var(--button-brand-text);
    }

    &:active {
      transform: scale(0.95);
    }
  }

  .primary-outlined-btn {
    background-color: transparent;
    color: var(--button-primary-text);
    border: 1px solid var(--button-primary-border);
    border-radius: 8px;

    svg {
      path {
        fill: var(--icon-color-primary);
      }
    }

    &:active {
      transform: scale(0.95);
    }
  }

  .success-filled-btn {
    background-color: var(--button-primary);
    color: var(--button-primary-text);
    text-transform: uppercase;

    svg path {
      fill: var(--button-primary-text);
    }

    &:active {
      transform: scale(0.95);
    }

    &.success-filled-btn--disabled {
      background: var(--background-surface-3);
      color: var(--text-secondary);
    }
  }

  .success-outlined-btn {
    background-color: transparent;
    color: var(--success-btn-outlined-text);
    text-transform: uppercase;
    border: 1px solid var(--success-btn-outlined-border);

    &:active {
      transform: scale(0.95);
    }
  }

  .brand-filled-btn {
    background-color: var(--button-primary);
    color: var(--button-primary-text);
    text-transform: uppercase;
    border-radius: 8px;

    svg path {
      fill: var(--button-primary-text);
    }

    &:active {
      transform: scale(0.95);
    }
  }

  .secondary-btn {
    background-color: var(--button-secondary);
    color: var(--button-secondary-text);
    border-radius: 8px;
    text-transform: uppercase;

    button {
      color: var(--button-secondary-text);
    }
  }

  .secondary-btn-without-icon {
    background-color: var(--button-secondary);
    color: var(--button-secondary-text);
    border-radius: 8px;

    &:active {
      transform: scale(0.95);
    }
  }

  .error-btn {
    // TO DO change color to pallete color
    background-color: var(--error-btn-bg);
    color: var(--text-error);
    border-radius: 8px;
    text-transform: uppercase;

    svg {
      g path,
      path {
        fill: var(--text-error) !important;
      }
    }

    &:active {
      transform: scale(0.95);
    }
  }

  .error-text-btn {
    background-color: transparent;
    color: var(--text-error) !important;

    svg {
      g path,
      path {
        fill: var(--text-error) !important;
      }
    }

    &:active {
      transform: scale(0.95);
    }
  }

  .light-outlined-btn {
    background-color: var(--button-ghost);
    color: var(--button-ghost-text);
  }

  .light-btn {
    color: var(--light-btn-text);
    background-color: var(--light-btn-bg);
    border-radius: 8px;
  }
}

// ONLY DESKTOP
.desktop {
  .template_noFullScreen {
    .primary-filled-btn {
      &:hover {
        background-color: var(--button-brand-hover);
      }
    }

    .primary-outlined-btn {
      &:hover {
        border: 1px solid var(--button-primary-border-hover);
        background-color: var(--button-primary-hover);
      }
    }

    .success-filled-btn {
      &:hover {
        background-color: var(--button-primary-hover);
      }
    }

    .success-outlined-btn {
      &:hover {
        background-color: var(--success-btn-outlined-hover-bg);
      }
    }

    .brand-filled-btn {
      &:hover {
        background-color: var(--button-primary-hover);
      }
    }

    .secondary-btn-without-icon {
      &:hover {
        background-color: var(--button-secondary-hover);
      }
    }

    .error-btn {
      &:hover {
        // TO DO change color to pallete color
        background-color: var(--error-btn-hover-bg);
      }
    }

    .error-text-btn {
      &:hover {
        // TO DO change color to pallete color
        color: var(--error-btn-hover-bg);
      }
    }

    .light-outlined-btn {
      &:hover {
        background: --button-ghost-hover;
      }
    }

    .light-btn {
      &:hover {
        background: var(--light-btn-hover-bg);
      }
    }
  }
}
