.raffle_draw_prize_pools_container {
  width: 100%;

  .raffle_draw_prize_pools_title {
    font-size: 18px;
    color: var(--text-brand);
    text-transform: capitalize;
  }

  .raffle_draw_prize_pools_table {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid var(--border-primary);
    border-radius: 8px 8px 0px 0px;

    &__header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 12px 16px;
      border-radius: 8px 8px 0px 0px;
      border-bottom: 1px solid var(--border-primary);
      background-color: var(--background-surface-2);

      &_column {
        flex: 1;
        text-transform: uppercase;
      }
    }

    &__body {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      &_row {
        width: 100%;
        padding: 0px 16px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid var(--border-primary);

        &:nth-child(2n) {
          background-color: var(--background-surface-2);
        }

        &:last-child {
          border: none;
        }
      }

      &_column {
        flex: 1;
        min-width: 50px;
        text-transform: uppercase;
        padding: 10px 0px;

        span {
          font-size: 14px;
        }
      }
    }
  }
}
