@import '../../../styles/sizes.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/constants';

.arrow-right,
.arrow-left {
  z-index: $slider__banner-small-arrows-wrapper--index;
}

.banner_container {
  padding: 0;
  margin: 0 0 10px;
  width: 100%;
  display: block;
  position: relative;
  z-index: $slider__banner-container--index;

  .banner_small_arrows {
    position: absolute;
    bottom: 30px;
    right: 12px;
    justify-content: flex-end;
    display: flex;
    z-index: $slider__banner-small-arrows--index;

    .arrows_wrapper {
      display: flex;
      justify-content: center;
      border-radius: 8px;
      position: relative;
      left: 89px;
      z-index: $slider__banner-small-arrows-wrapper--index;
    }

    .arrows_wrapper_overlay {
      position: relative;
      z-index: $slider__banner-small-arrows-wrapper-overlay--index;
      background: var(--background-surface-3);
      border-radius: 8px;
      width: 93px;
      opacity: 0.65;
      position: relative;
    }

    .arrow {
      background: var(--background-surface-3);
      border: 1px solid var(--border-primary);
      color: var(--text-primary);
      border-radius: 5px;
      height: 32px;
      width: 32px;
      margin: 5px;
      display: flex;
      justify-content: center;
      position: relative;
      z-index: $slider__banner-small-arrows-arrow--index;
      padding: 0;

      &:hover {
        opacity: 0.8;
        background: var(--background-surface-3);
      }

      &:nth-child(2) {
        div > div {
          left: 1px;
        }
      }

      div > div {
        height: 32px;
        width: 32px;
        position: relative;
        top: -1px;
      }

      svg {
        height: 21px;
        width: 9px;
      }
    }
  }

  .banner_centered_arrows {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: calc(100% - 20px);

    &.center-out {
      .btn_container {
        width: 100%;

        .left {
          position: relative;
          right: 10px;
        }

        .right {
          position: relative;
          left: 10px;
        }
      }
    }

    .btn_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 95%;
      margin: 0 auto;
    }

    .arrow {
      background: var(--background-surface-2);
      border: 2px solid var(--border-primary);
      border-radius: 4px;

      &.md {
        width: 24px;
        height: 24px;
      }

      &.lg {
        width: 40px;
        height: 40px;
      }

      svg path {
        fill: var(--text-primary);
      }

      &:hover {
        opacity: 0.9;
      }
    }

    &.centerIn {
      .btn_container {
        width: 97%;
      }
      .arrow {
        background-color: var(--button-brand);
        border: none;
        width: 32px;
        height: 32px;
      }
    }
  }

  .banner_arrows {
    position: relative;
    top: 18%;
    justify-content: flex-end;
    display: flex;
    z-index: $slider__banner-arrows--index;

    .arrow {
      height: 80px;
      width: 50px;
    }
  }

  //arrows
  .arrows_with_counter_container {
    display: flex;
    height: inherit;
    position: relative;

    button {
      margin: 0 7px;
    }

    p {
      margin: 0;
    }

    &.leftTop {
      align-items: flex-start;
      top: 10px;
      left: 35px;
    }

    &.leftBottom {
      align-items: flex-end;
      bottom: 10px;
      left: 35px;
    }

    &.rightTop {
      align-items: flex-start;
      justify-content: flex-end;
      top: 10px;
      right: 35px;
    }

    &.rightBottom {
      justify-content: flex-end;
      align-items: flex-end;
      bottom: 10px;
      right: 35px;
    }
  }

  .default {
    font-size: 150px;
  }
}

.small_banner_buttons_container {
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 100%;

  button {
    position: absolute;
    bottom: 5px;
    right: 16px;
    height: 36px;
    width: 50px;
    border-radius: 4px;
    border: none;
    outline: none;
    font-family: Inter-Bold;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    transition: 0.35s ease-out;
    text-transform: uppercase;

    svg {
      transform: rotate(-90deg);
    }
  }
}

.banner_buttons_container {
  display: flex;
  position: relative;

  button {
    position: absolute;
    bottom: 0;
    height: $btn-md-height;
    padding: 0 20px;
    border-radius: 8px;
    border: none;
    outline: none;
    font-family: Inter-Bold;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    transition: 0.35s ease-out;
    text-transform: uppercase;
    max-width: 300px;
    width: fit-content;
  }

  &.bottomRight {
    justify-content: flex-end;
  }

  &.bottomCenter {
    justify-content: center;
    bottom: 40px;
  }

  &.bottomLeft {
    justify-content: flex-start;
    bottom: 10px;
    left: 16px;
  }
}

//new
//carousel
.carousel_container {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  width: inherit;
  height: fit-content!important;

  .carousel_item_container {
    display: flex !important;
    margin-bottom: 0;
  }

  .slide_item {
    height: inherit;
    overflow: hidden;
    width: 100%;

    img {
      height: inherit;
      width: 100vw;
    }

    &:hover {
      &::after {
        opacity: 1;
      }
    }

    .banner_inner_button {
      display: flex;
      align-items: center;
      position: absolute;
      height: $headerBtnDesktopHeight;
      border-radius: 5px;
      padding: 3px 10px;

      &.leftTop {
        top: 5%;
        left: 7%;
      }

      &.leftBottom {
        bottom: 5%;
        left: 7%;
      }

      &.rightTop {
        right: 5%;
        top: 5%;
      }

      &.rightBottom {
        bottom: 5%;
        right: 5%;
      }
    }

    .banner_inner_content {
      position: absolute;
      width: 100%;
      padding: 0 15px;

      &.leftTop {
        top: 20%;
        left: 0;
      }

      &.leftBottom {
        bottom: 15%;
        left: 5%;
      }

      &.rightTop {
        right: 5%;
        top: 15%;
      }

      &.rightBottom {
        bottom: 15%;
        right: 5%;
      }
    }
  }
}

.desktop {
  .home_slider_container {
    margin: 40px 0;
  }

  .bannerItem img {
    width: 100%;
  }

  .small_bannerItem {
    img {
      height: 204px;
    }
  }
}

.mobile {
  .game_container.template_1 .game_container_header,
  .game_container.template_2 .game_container_header {
    padding: 0;
    position: relative;
    top: 0;
  }

  .mobile_game_carousel_container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 0;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .bannerItem img {
    width: 100vw;
  }

  .game_container.template_4 {
    background-color: initial;
    display: flex;
    flex-direction: column;
    width: 100%;

    .template_4_header {
      padding: 10px 12px;
      color: var(--text-primary);

      h4 {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
      }
    }

    .template_4_content {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      padding: 0 12px;
      width: 100%;
      z-index: $slider__game-container-template-4-content--index;

      &:hover {
        img {
          border: 2px solid var(--border-primary);
        }
      }
    }
  }
}

.game_container {
  &.template_3 {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    overflow: hidden;
    margin: 0 0 10px 0;

    .game_container_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 46px;
      background: linear-gradient(45deg, var(--background-surface-3), transparent);
      border-left: 12px solid var(--button-brand-border);
      text-transform: capitalize;
      width: 100%;
      text-transform: uppercase;

      &_title_with_arrows {
        padding: 0 5px;

        .tag_title {
          font-family: Inter;
          font-size: 20px;
          color: var(--text-primary);
          margin-right: 20px;
          margin-bottom: 0;
        }
      }

      .viewAll {
        height: auto;
        border-radius: 0;
        padding: 0;
        font-family: Inter;
        font-size: 14px;
        line-height: 22px;
        text-transform: uppercase;
        white-space: nowrap;
        color: var(--text-primary);
        width: fit-content;

        &:focus,
        &:hover {
          color: var(--text-brand);
        }

        &:after {
          display: inline-block;
          -webkit-transform: scale(0.5, 1);
          -moz-transform: scale(0.5, 1);
          -ms-transform: scale(0.5, 1);
          -o-transform: scale(0.5, 1);
          transform: scale(0.5, 1);
          content: '>';
          position: relative;
          margin-left: 2px;
          font-size: 16px;
        }
      }

      .arrows {
        display: none;
      }
    }

    .template_3_carousel_conatiner {
      display: flex;
      position: relative;
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .inner_carousel div {
      display: flex !important;
    }

    .arrows {
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 54%;
      z-index: $slider__game-container-template-3-arrows--index;
      width: 100%;
    }
  }

  &.template_5, &.template_1, &.template_2 {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
    position: relative;
    overflow: hidden;
    margin: 0;

    .template_3_carousel_conatiner {
      display: flex;
      position: relative;
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .game_container_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 46px;
      text-transform: capitalize;
      width: 100%;
      text-transform: uppercase;

      &_title_with_arrows {
        padding: 0 5px;

        .tag_title {
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          color: var(--text-primary);
          margin: 0;
          padding: 0;
        }
      }

      .game_btn_container {
        display: flex;

        .arrows {
          display: flex;
          justify-content: space-between;
          z-index: $slider__game-container-header-game-btn-container-arrows--index;
        }

        .viewAll {
          height: 32px;
          padding: 0 16px;
          font-family: Inter;
          font-size: 14px;
          line-height: 22px;
          text-transform: uppercase;
          white-space: nowrap;
          color: var(--text-primary);
          border-radius: 4px;
          margin-right: 5px;

          &:focus,
          &:hover {
            color: var(--text-brand);
          }

          &:after {
            display: inline-block;
            -webkit-transform: scale(0.5, 1);
            -moz-transform: scale(0.5, 1);
            -ms-transform: scale(0.5, 1);
            -o-transform: scale(0.5, 1);
            transform: scale(0.5, 1);
            content: '>';
            position: relative;
            margin-left: 2px;
            font-size: 16px;
          }
        }
      }
    }
  }

  &.template_4 .template_4_content {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-self: center;

    &::-webkit-scrollbar {
      display: none;
    }

    div {
      display: flex;
      align-items: center;
      padding: 0;

      &:hover {
        img {
          border: 2px solid var(--border-primary);
        }
      }
    }
  }

  .arrows {
    display: flex;
  }

  &.template_1 {
    height: 400px;
    margin: 0 0 48px 0;
  }

  &.template_2 {
    margin: 0 0 48px 0;
  }

  &.template_1,
  &.template_2 {
    &:first-child {
      margin-top: 0;
    }

    .game_container_header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &_title_with_arrows {
        display: flex;

        .tag_title {
          font-family: Inter-Bold;
          font-size: 20px;
          text-transform: capitalize;
          color: var(--text-secondary);
          margin-right: 20px;
          margin-bottom: 0;
          padding: 0 0 10px;
        }
      }

      .viewAll {
        height: auto;
        border-radius: 0;
        padding: 0;
        margin-left: auto;
        font-family: Inter-SemiBold;
        font-size: 14px;
        line-height: 22px;
        text-transform: uppercase;
        white-space: nowrap;
        color: var(--text-primary);
        width: fit-content;

        &:focus,
        &:hover {
          color: var(--text-brand);
        }
      }
    }
  }
}

.roundedLight {
  background-color: var(--primary-0);
  color: #141111;
  height: $rounded_game_size;
  width: $rounded_game_size;
  border-radius: 50%;

  p {
    font-size: 52px;
    position: relative;
    top: -7px;
    left: 5px;
  }
}

.roundedDark {
  background-color: rgba(0, 0, 0, 0.16);
  height: $rounded_game_size;
  width: $rounded_game_size;
  border-radius: 50%;

  p {
    font-size: 40px;
    position: relative;
    top: -6px;
    left: 7px;
  }
}

.arrow.btn_arrow__right,
.arrow.btn_arrow__left {
  & + span.visually-hidden {
    display: none;
  }

  &:focus {
    outline: unset;
  }
}

.squaredDark {
  width: $sliderArrowSize;
  height: $sliderArrowSize;
  border-radius: 5px;
  background: var(--background-surface-3);
  margin: 0 0.3rem;
  color: var(--text-primary);
  position: relative;
  display: flex!important;
  justify-content: center;
  align-items: center;

  &:hover {
    color: var(--text-brand);
  }

  p {
    font-size: 25px;
    font-family: Inter-SemiBold;
    position: relative;
    top: -9px;
    left: 0;
  }

  &.disabled {
    color: var(--text-primary);
    opacity: 0.6;

    svg {
      top: -1px;
      left: -1px;
      position: relative;
    }
  }
}

.game_carousel_conatiner {
  position: absolute;
  height: inherit;
  width: 100%;

  .inner_carousel {
    .template_2 {
      display: flex;
      padding: 0 26px;
      width: 100%;
    }
  }
}

//medias
.desktop {
  .game_carousel_container {
    .inner_carousel {
      .slider_template_1 {
        width: 100%;
        height: 365px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        overflow-x: auto;
        overflow-y: hidden;
        align-content: baseline;
        cursor: ew-resize;

        .second_col {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          height: inherit;
          justify-content: space-around;
        }

        @include hideScrollbar();
      }

      .slider_template_2 {
        width: 100%;
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        cursor: ew-resize;

        &::-webkit-scrollbar-track {
          background-color: transparent;
        }

        &::-webkit-scrollbar {
          width: 0;
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          height: 0;
        }
      }
    }
  }
}

.tablet {
  .bannerItem img {
    width: 100vw;
    object-fit: cover;
    border-radius: 0 !important;
  }

  .game_carousel_container {
    .inner_carousel {
      .slider_template_1 {
        width: 100%;
        height: 365px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        overflow-x: auto;
        overflow-y: hidden;
        align-content: baseline;
        cursor: ew-resize;

        .second_col {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          height: inherit;
          justify-content: space-around;
          margin-top: -5px;
        }

        @include hideScrollbar();
      }

      .slider_template_2 {
        width: 100%;
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        cursor: ew-resize;

        &::-webkit-scrollbar-track {
          background-color: transparent;
        }

        &::-webkit-scrollbar {
          width: 0;
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          height: 0;
        }
      }
    }
  }

  .game_container {
    padding: 0 0 0 24px;

    .game_container_header {
      padding: 0 24px;
    }
  }
}

.mobile {
  .banner_container {
    margin: 0 0 10px;

    .slick-slide img {
      width: 100vw;
      height: auto;
    }

    .banner_buttons_container {
      button {
        font-size: 16px;
        font-family: Inter-Bold;
      }

      &.bottomRight {
        justify-content: flex-end;

        button {
          width: fit-content;
        }
      }

      &.bottomCenter {
        bottom: 14px;
        margin: 0 20px;

        button {
          width: 100%;
        }
      }

      &.bottomLeft {
        justify-content: flex-start;
        width: 90%;
        bottom: 15px;
        left: 15px;

        button {
          width: fit-content;
        }
      }
    }
  }

  .home_slider_container {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
  }

  .game_container {
    &.template_2{
      margin: 0 0 0;
      border-bottom: 0.5px solid #FFFFFF80;
      padding-bottom: 6px;
      &:last-child {
        border-bottom: none;
        padding-bottom: 10px;
      }
    }
    &.template_2 {
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: unset;
      margin-top: 0;

      .template_2 {
        &:nth-child(4) {
          position: absolute;
          right: -20px;
        }
      }

      .game_container_header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-transform: uppercase;
        padding: 0 2px;

        &_title_with_arrows {
          margin-top: 0;
          padding: 0;

          .tag_title {
            font-family: Inter;
            font-size: 20px;
            color: var(--text-secondary);
            margin-right: 20px;
            margin-bottom: 0;
            padding: 0;
            height: 40px;
            width: fit-content;
          }
        }

        .viewAll {
          height: auto;
          border-radius: 0;
          padding: 0 5px;
          font-family: Inter-SemiBold;
          font-size: 14px;
          line-height: 22px;
          text-transform: uppercase;
          white-space: nowrap;
          color: var(--text-secondary);
          margin: 0 !important;

          &:focus,
          &:hover {
            color: var(--text-brand);
          }
        }

        .arrows {
          display: none;
        }
      }

      .slider_template_2 {
        display: flex;
        max-width: 300px;
        height: 135px;
        overflow-y: hidden;
        overflow-x: auto;

        &::-webkit-scrollbar-track {
          background-color: transparent;
        }

        &::-webkit-scrollbar {
          width: 0;
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          height: 0;
        }
      }
    }
  }

  .game_container {
    .tag_title {
      margin-bottom: 8px;
    }
  }

  .game_carousel_conatiner {
    .inner_carousel {
      .template_2 {
        display: flex;
        width: 100%;
        padding: 0;

        &:nth-child(4) {
          position: absolute;
          right: -20px;
        }
      }
    }
  }

  .banner_arrows {
    display: none;
  }

  .game_container {
    padding: 0;
    background-color: transparent;
    min-height: unset;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }

    &_header {
      padding: 0;

      &_title_with_arrows {
        justify-content: space-between;
        width: 100%;
        padding: 0 0 10px 5px;

        .tag_title {
          font-size: 16px !important;
        }
      }

      .viewAll {
        font-size: 12px !important;
        margin-bottom: 8px !important;
      }
    }
  }
}

.swiper-pagination {
  bottom: 0px !important;
  position: relative !important;

  .swiper-pagination-bullet {
    box-shadow: 1px 1px 2px rgb(0 0 0 / 90%);
    background: var(--icon-color-secondary);

    &:hover {
      opacity: 0.75;
    }
  }
}
