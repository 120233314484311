@import '../../../configs/medias.scss';
@import '../../../styles/sizes.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/constants';

.game {
  cursor: pointer;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .jackpot_value {
    position: absolute;
    color: var(--text-primary);
    z-index: 10000000;
    right: 0;
    background: var(--background-surface-brand);
    width: 100%;
    bottom: -5px;
    @include center();
    font-size: 13px;
  }

  &.showDetails {
    padding: 5px;
    background-color: var(--background-surface-3);
    border-radius: 8px;

    .game__image,
    .image_wrapper > div {
      padding: 5px 0;
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
    z-index: $game-card__game-image--index;
  }

  .image_wrapper {
    @include center();

    position: relative;
    z-index: $game-card__game-card-image-wrapper--index;
    height: 100%;
    width: 100%;

    .game_overlay {
      @include center();

      width: 100%;
      position: absolute;
      bottom: 0;
      top: 0px;
      background: rgba(37, 49, 64, 0.85);
      color: #f1f1f1;
      transition: 0.5s ease;
      font-size: 20px;
      text-align: center;
      z-index: $game-card__game-card-game-overlay--index;

      .img_play {
        width: $game_img_play_size;
        height: $game_img_play_size;
        // TO DO it be a main-0
        background: var(--button-ghost);
        border-radius: 50%;
        position: relative;
        display: block;
        margin: 100px auto;

        &:hover {
          // check
          box-shadow: 0px 0px 25px 3px var(--button-ghost-hover);

          &::after {
            border-left: 20px solid var(--border-primary);
          }
        }

        &::after {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-35%) translateY(-50%);
          transform-origin: center center;
          height: 0;
          width: 0;
          border-top: 12px solid transparent;
          border-bottom: 12px solid transparent;
          border-left: 20px solid var(--border-primary);
          z-index: $game-card__game-card-game-overlay-img-play-after--index;
        }
      }

      .btn_play {
        height: 32px;
        padding: 8px 16px 8px 19px;
        font-family: Inter-Bold;
        font-size: 12px;
        text-transform: uppercase;
        background: var(--button-ghost);
        color: var(--button-ghost-text);
        border-radius: 4px;
        display: flex;
        align-items: center;

        &_icon {
          height: 9px;
          width: 10px;
          margin-right: 5px;
        }
      }
    }
  }

  .spinner {
    align-items: center;
    position: absolute;
    width: 100%;
  }

  .details {
    padding: 3px 1px 10px;
    height: 45px;

    .games__title {
      text-align: left;
      font-size: 12px;
      font-family: Inter-Medium;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 150px;
      white-space: nowrap;
      display: block;
      color: var(--text-secondary);
      margin-bottom: 5px;
    }

    .games__provider_name {
      color: var(--text-secondary);
      font-size: 10px;
      text-transform: capitalize;
      margin: 0;
    }
  }

  .lds_crown {
    display: inline-block;
    position: relative;
    text-align: center;
    transform: translateZ(1px);

    &__inner {
      vertical-align: middle;
      position: relative;
      top: -10px;
      margin: auto;

      &__crown {
        background-image: url('./loaders/giphy.gif');
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
}

@keyframes lds-circle {
  0% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }

  100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }

  0% {
    transform: rotateY(0deg);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    transform: rotateY(1800deg);
  }

  100% {
    transform: rotateY(3600deg);
  }
}

@media (max-width: $tabletMin) {
  .text_container {
    margin: 50px auto;
  }

  .game {
    margin: 0 8px 4px 0;

    div {
      .img_play {
        margin: auto !important;
      }
    }
  }
}
