@import '../../../styles/mixins.scss';
@import '../../../styles/constants';

.birthDate_input__wrapper {
  width: 100%;
  margin: 7px 0 13px;
  position: relative;
  color: var(--text-secondary);

  .label {
    white-space: nowrap;
    font-size: 14px;
    font-family: Inter;
    text-align: left;
    margin-bottom: 0px !important;
    text-transform: capitalize;
    line-height: 1.5;

    span {
      color: red;
    }
  }

  .rangepicker {
    display: flex;
    width: 100%;
    margin-top: 6px;

    .div {
      width: 100%;
    }

    .picker {
      border-radius: 10px;
      height: 40px;
      padding: 0 10px;
      width: 100%;
      border: 1px solid var(--input-border-default);
      color: var(--text-primary);
      background-color: var(--input-background-surface-1);
    }

    .ant-picker-date-panel {
      width: 100%;
    }

    .ant-picker-clear {
      display: none;
    }
    .ant-picker-suffix {
      cursor: pointer;
      pointer-events: unset;
    }
  }
  .ant-picker-disabled {
    input {
      color: var(--input-background-disable)!important;
    }
  }
}
