.closure_container {
  .closure_txt {
    color: var(--text-primary);
  }
  .closureRow {
    display: flex;
    gap: 70px;
    .radio_buttons_wrapper {
      display: block;
      .radio-label {
        display: block;
      }
    }
    .closure_input{
      width: 350px;
    }
  }
  button {
    background-color: var(--button-primary);
    color: var(--button-primary-text);
    border-radius: 8px;
    margin-top: 12px;
    margin-bottom: 20px;
    width: 350px;
    &:disabled {
      background: var(--background-surface-3);
      color: var(--text-secondary);
    }
  }
  .error_msg {
    margin-right: 21.5%;
    color: red;
    display: flex;
    font-size: 12px;
    justify-content: flex-end;
  }
}

.mobile {
  .closure_container {
    padding: 10px 20px 20px;
    .closureRow {
      display: block;
      .radio_buttons_wrapper {
        width: 100%;
        .radio-label {
          margin-right: 0;
        }
        .radio-text {
          color: var(--text-secondary);
          text-align: left;
          margin-left: 14px;
        }
      }
    }
  }
}