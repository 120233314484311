.raffle_draw_rules_container {
  width: 100%;

  .raffle_draw_rules_title {
    font-size: 18px;
    color: var(--text-brand);
    text-transform: capitalize;
  }

  .raffle_draw_rules_content {
    background-color: var(--background-surface-2);
    border-radius: 8px;
    padding: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .raffle_draw_tickets_img {
    height: 150px;
    width: auto;
  }

  .raffle_draw_rule_text {
    margin-left: 80px;
    font-size: 16px;

    .prize_value {
      font-family: 'Inter-Bold';
      color: var(--text-brand);
    }
  }
}

// MOBILE
.mobile {
  .raffle_draw_rules_container {
    .raffle_draw_tickets_img {
      height: 80px;
    }
    .raffle_draw_rule_text {
      margin-left: 20px;
    }
  }
}
