.timer {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

// TEXT BOLD
.timer {
  &_text__bold {
    font-family: 'Inter-Bold';
  }
}

// TEXT BOLD
.timer {
  &_text__uppercase {
    text-transform: uppercase;
  }
}

// VIEW
.timer {
  &_view {
    &__dots {
      gap: 4px;
    }

    &__names {
      gap: 4px;
    }
  }
}

// SIZE
.timer {
  &_size {
    &__xxs {
      font-size: 8px;
    }

    &__xs {
      font-size: 10px;
    }

    &__sm {
      font-size: 12px;
    }

    &__md {
      font-size: 14px;
    }

    &__lg {
      font-size: 16px;
    }

    &__xl {
      font-size: 18px;
    }

    &__xxl {
      font-size: 20px;
    }

    &__xxxl {
      font-size: 22px;
    }
  }
}
