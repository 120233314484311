@import '../../../styles/constants';

.raffle_draw_content_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: var(--text-primary);
}

.raffle_draw_tabs_wrapper {
  width: 100%;
  background: var(--background-surface-1);
  padding: 24px 0px;
}

.raffle_draw_tabs {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 24px;

  .tab_item {
    flex-grow: 1;
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    width: 100%;
    border: 1px solid var(--border-primary);

    &.selected {
      border: 1px solid var(--border-primary-selected);
    }

    .raffle_draw_name {
      font-size: 24px;
      font-family: 'Inter-Bold';
      color: var(--text-primary);
    }

    .raffle_draw_prize {
      font-size: 24px;
      font-family: 'Inter-Bold';
      color: var(--text-brand);
    }
  }
}

.raffle_draw_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .raffle_draw_banner {
    width: 100%;
    margin-bottom: 24px;
  }

  .raffle_draw_rule {
    width: 100%;
    margin-bottom: 30px;
  }

  .raffle_draw_prize_pools {
    width: 100%;
    margin-bottom: 30px;
  }

  .raffle_draw_tickets {
    width: 100%;
    margin-bottom: 24px;
  }
}

.desktop {
  &.template_noFullScreen {
    .raffle_draw_content_wrapper {
      &.with_tabs {
        position: relative;
        top: -12px;

        .raffle_draw_tabs_wrapper {
          top: calc($headerTopLineHeight-2Rows + $headerBottomLineHeight-2Rows - 12px);
          position: sticky;
          left: 0;
          z-index: $base-overlapping-index;
        }
      }
    }
  }

  .template_with_sidebar {
    .raffle_draw_content_wrapper {
      padding-bottom: 24px;
      &.with_tabs {
        position: relative;
        top: -24px;

        .raffle_draw_tabs_wrapper {
          top: calc(88px - 24px);
          position: sticky;
          left: 0;
          z-index: $base-overlapping-index;
        }
      }
    }
  }
}

// MOBILE
.mobile {
  .raffle_draw_content_wrapper {
    padding: 12px;

    .raffle_draw_tabs_wrapper {
      padding: 0px 0px 24px;
      .raffle_draw_tabs {
        gap: 12px;

        .tab_item {
          padding: 6px 8px;

          .raffle_draw_name,
          .raffle_draw_prize {
            white-space: wrap;
            font-size: 14px;
            text-align: center;
          }
        }
      }
    }
  }
}
