@import '../../../../styles/mixins.scss';
@import '../../../../styles/sizes.scss';

.promotions-info-page {
  padding: 0 0 20px;

  .promo-btn {
    min-width: 250px;
    width: fit-content;
    margin: 0 auto;
  }

  &__back-button-wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: var(--text-secondary);
    padding: 0;
    
    :first-child {
      margin: unset;
    }

    span {
      font-family: Inter-Medium;
      font-size: 14px;
      line-height: 17px;
    }

    svg {
      height: 13px;
      width: 30px;
      g,path{
        fill: var(--text-secondary);
      }
    }
  }

  &__description-wrapper {
    margin-top: 10px;

    .promo_frame {
      width: $maxWidth;
      height: 100%;
      height: 70vh;
      height: 70svh;
      border-radius: 8px;
    }
    .loader-container {
      width: 90vw;
      height: 80vh;
      display: flex;
    }
  }

  &__description-img {
    border-radius: 6px;
    width: 100%;
    margin-bottom: 30px;
  }

  &__description-title {
    margin-top: 20px;
    font-family: Inter-Bold;
    font-size: 28px;
    line-height: 34px;
    color: var(--text-secondary);
  }

  &__description-text {
    margin-top: 16px;
    font-family: Inter-Medium;
    font-size: 16px;
    line-height: 19px;
    color: var(--text-secondary);

    p {
      margin: 5px;
    }

    p span {
      font-family: 'Inter' !important;
      line-height: 24px !important;
    }
  }
}

//mobile
.mobile {
  .promotions-info-page {
    &__back-button-wrapper {
      margin-top: 11px;
      padding-left: 11px;
    }

    .promotions-info-page__description-wrapper {
      margin: 8px 15px;

      .promo_frame {
        width: 92vw;
        height: 100%;
        height: 70vh;
        height: 70svh;
        border-radius: 8px;
      }
    }

    &__description-title-text-button-wrapper {
      padding: 0;
      width: inherit;
    }

    &__description-title {
      font-size: 16px;
      line-height: 24px;
    }

    &__description-text {
      font-size: 14px;
      margin-top: 12px;
      line-height: 25px;
    }
  }
}
