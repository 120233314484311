.kyc_container {
  p {
    color: var(--text-primary);
  }
}

.mobile {
  .kyc_container {
    padding: 10px 20px 20px;
  }
}

.kyc_container_btn {
  background-color: var(--button-primary);
  color: var(--button-primary-text);
  border-radius: 8px;
  margin-top: 12px;
  margin-bottom: 20px;
  width: 350px;
  &:disabled {
    background: var(--background-surface-3);
    color: var(--text-secondary);
  }
}
.kyc_info_message {
  display: flex;
  align-items: center;
  &_btn {
    max-width: fit-content;
  }
}
