@import '../../../styles/sizes.scss';
@import '../../../styles/mixins.scss';

.poker_content_wrapper {
  display: flex;
  position: sticky;
  &__banner {
    position: relative;
    img {
      border-radius: 2px;
    }
  }

  .play_now_button {
    text-transform: uppercase;
    width: 585px;
    height: 44px;
    padding: 16px;
  }

  .read_more_button {
    color: var(--text-primary);
    text-transform: uppercase;
    text-decoration: underline;
    padding-left: 12px;
  }
  .see_more_button {
    color: var(--text-primary);
    text-transform: uppercase;
    font-weight: 700;
    border: 1px solid #0f5903;
    height: 39px;
    margin-top: 16px;
    border-radius: 8px;
  }
  .cards_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 23px;
  }

  &__actionsContainer {
    @include center();
    justify-content: space-between;
    margin-top: 40px;
    &__title {
      color: var(--text-primary);
      font-size: 40px;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 0;
    }

    @media (max-width: 481px) {
      flex-direction: column-reverse;
      align-items: flex-start;
      padding: 0 12px;
      margin-top: 15px;
      &__title {
        font-size: 24px;
        line-height: 32px;
        margin-top: 20px;
        margin-bottom: 8px;
      }
    }
  }
  &__content {
    color: var(--text-primary);
    padding-bottom: 32px;
    margin-top: 20px;
  }
  @media (max-width: 481px) {
    .play_now_button {
      width: 100%;
      height: 48px;
    }
    .cards_container {
      grid-template-columns: repeat(1, 1fr);
      gap: 12px;
      margin-top: 24px;
      padding: 12px;
    }
    &__content {
      padding: 0 12px;
      font-size: 14px;
      margin-top: 0;
      margin-bottom: 8px;
    }
  }
}
