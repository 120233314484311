.newsLetter_container {
  height: 30px;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  position: relative;
  background: var(--newsletter-background);
  white-space: nowrap;

  .text-wrapper {
    display: flex;
    min-width: 100%;
    animation: moving 30s linear infinite;
    .text {
      color: var(--newsletter-text);
      font-family: Inter, sans-serif;
      font-size: 12px;
      font-weight: bold;
      display: inline-block;
      white-space: nowrap;
      padding-right: 40px;
    }
  }
}

.desktop .newsLetter_container,
.tablet .newsLetter_container {
  &.dark {
    background: var(--dark);
    color: var(--light);
  }

  &.light {
    background: var(--light);
    color: var(--dark);
  }

  &.primary {
    background: var(--newsletter-background);
    .text {
      color: var(--newsletter-text);
    }
  }
}

.mobile .newsLetter_container {
  border: none;
  border-radius: 0;
  height: 27px;
  line-height: 30px;
  width: 100vw;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  .text-wrapper {
    display: inline-block;
    min-width: 250%; /* Ensure it’s wide enough */
    white-space: nowrap;
    will-change: transform;
    backface-visibility: hidden;
    perspective: 1000px;
    contain: paint;
  }
}

/* Infinite scrolling animation */
@keyframes moving {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes moving {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}