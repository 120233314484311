@import '../../../../../../../styles/mixins.scss';

.limit_title {
  margin-top: 15px;
  color: var(--text-primary);
}
.limitRow {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  &_tilte {
    margin: 0 10px 0 0;
    width: 120px;
    color: var(--text-primary);
    text-align: start;
    padding-left: 10px;
  }
  &_body {
    display: flex;
    align-items: center;
    color: var(--text-secondary);
  }
  &_input {
    margin: 0 10px 0 0;
    width: 130px;
  }
  &_radio {
    .radio_buttons_wrapper {
      .radio-label {
        margin-bottom: 0;
      }
    }
  }
  &_current_checkbox {
    @include center;
    cursor: not-allowed;
    opacity: 0.5;
    margin-left: 12px;
    &_circle {
      cursor: pointer;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin-right: 5px;
      display: inline-block;
      position: relative;
      border: 2px solid #f4b400;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 10px;
        background: var(--button-brand);
        border-radius: 50%;
      }
    }
    &_text {
      font-size: 12px;
    }
  }
  &_btn {
    background-color: var(--button-primary);
    color: var(--button-primary-text);
    width: 95px;
    height: 30px;
    border-radius: 8px;
    &:disabled {
      background: var(--background-surface-3);
      color: var(--text-secondary);
    }
  }
  .reset-btn {
    background-color: var(--button-primary);
    color: var(--button-primary-text);
    width: 95px;
    height: 30px;
    border-radius: 8px;
  }
}

.current_limits {
  margin-top: 12px;
  border-top: 1px solid var(--account-background-surface-3);
  padding-top: 8px;

  .current_limits_title {
    color: var(--text-secondary);
  }
}

// MOBILE STYLES
.mobile {
  .limitRow {
    width: 100%;
    padding: 0px 10px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &_tilte {
      width: 100%;
      padding: 0px;
      margin: 0 0 4px 0;
    }

    &_body {
      gap: 8px;
    }
    &_radio {
      @include center;
      .radio_buttons_wrapper {
        gap: 8px;
        .radio-label {
          margin: 0;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .radio-input {
            width: 0px;
          }
          .custom-radio {
            top: 1px;
          }
        }
      }
    }
    &_input {
      margin: 0px;
      width: 100%;
      flex-grow: 1;
    }
  }

  .limitRow_btn {
    margin: 20px 0 0 10px;
  }
}
