@import '../../../styles/mixins.scss';

.regulation_icons_container {
  grid-area: regulation_icons;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 50px;

  .regulation_icons_item {
    @include center;
    .regulation_icons_link {
      cursor: pointer;
      text-decoration: underline;
    }
    .regulation_icons_img {
      height: 30px;
      width: auto;
      object-fit: contain;
    }
  }
}

// MOBILE
.mobile {
  .regulation_icons_container {
    display: grid;
    gap: 16px;
    row-gap: 24px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    width: 100%;
  }
}
