@import '../../../configs/medias.scss';
@import '../../../styles/sizes.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/constants';

.user_settings_wrapper {
  .logo {
    height: 30px;
    position: relative;
    top: 15px;
    margin: 0 auto;
  }

  .user_icon {
    @include center();

    width: $userSettingsWidth;
    height: $headerBtnDesktopHeight;

    .user_icons_inner_container {
      @include center();
    }
    .secondary-btn {
      svg {
        g path,
        path {
          fill: var(--button-secondary-text) !important;
        }
      }
  
      &:active {
        transform: scale(0.95);
      }
  
      &:hover {
        background-color: var(--button-secondary-hover);
      }
    }
    button {
      p {
        margin-top: 3px;
        font-family: Inter;
        padding: 0;
        font-size: 9px;
        max-width: 48px;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .arrow {
        cursor: pointer;
        height: 5px;
        width: 10px;
        margin-left: 5px;

        svg {
          transform: rotate(0deg);
        }
      }

      .up {
        transform: rotate(180deg);
      }
    }

    .user_header_avatar {
      font-size: 20px;
      display: flex;
      align-items: center;

      svg {
        transform: rotate(0deg);
      }
    }
  }

  .user_settings {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    width: 300px;
    border-radius: 10px;
    border-radius: 5px;

    .user {
      margin-right: 15px;
    }

    .user_settings_list {
      padding: 0;

      .logout_btn {
        margin-top: 20px;

        button {
          display: flex;
          height: 34px;
          align-items: center;
          cursor: pointer;
          padding: 0 25px;
          margin-top: 8px;
          font-style: normal;
          font-family: Inter-SemiBold;
          width: 100%;
          justify-content: flex-start;
        }
      }
    }

    .user_settings__separator_line {
      width: 219px;
      height: 0;
      margin: 10px auto;
    }

    &_info {
      display: flex;
      align-items: center;
      padding: 0 20px;
      margin-top: 30px;

      .user_avatar {
        width: $user_avatar_size;
        height: $user_avatar_size;
        background: var(--background-surface-3);
        border-radius: 50%;
        padding-top: 5px;
        margin-right: 6px;

        svg path {
          fill: var(--text-brand);
        }
      }

      .user_info {
        font-style: normal;
        font-family: Inter-SemiBold;
        display: flex;
        flex-direction: column;

        p {
          margin: 0;
        }
      }
    }
  }

  .user_settings_list {
    width: 100%;
    padding-bottom: 20px;

    li:not(.logout_btn) {
      button {
        display: flex;
        height: 34px;
        align-items: center;
        cursor: pointer;
        padding: 0 25px;
        font-style: normal;
        font-family: Inter-SemiBold;
        width: 100%;
        justify-content: flex-start;
      }
    }
  }
}

@keyframes appearance {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.desktop, .tablet {
  .user_settings_wrapper {
    margin: 0 5px;

    .user_settings {
      right: 0;
    }

    .user_icon {
      button {
        p > div {
          display: flex;
          align-items: center;
        }
      }
    }

    .user_settings {
      z-index: $user-settings--index;

      .user_settings_list {
        li:nth-child(5) {
          svg {
            path {
              stroke: var(--text-secondary);
            }
          }
        }
        li:nth-child(2) {
          svg {
            path {
              stroke: var(--main-text-color-0);
            }
          }
        }
        li:nth-child(4) {
          svg {
            path {
              stroke: var(--text-secondary);
            }
          }
        }

        svg {
          path {
            fill: var(--text-secondary);
          }
        }

        .logout_btn {
          button {
            margin: 0;
            justify-content: center;

            svg {
              margin-right: 15px;

              path {
                fill: red;
              }
            }
          }
        }

        li {
          margin: 0 20px 8px;
          width: 88%;
          border-radius: 4px;

          button {
            cursor: pointer;
            padding: 0 19.5px;
            height: 40px;
            text-align: left;
            outline: none;
            font-size: 16px;
            transition: 0.4s;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
        }
      }
    }
  }

  .balance_wrapper {
    button {
      height: $headerBtnDesktopHeight;
    }
  }
}

//mobile
.mobile .user_settings_wrapper {
  .user_settings_info {
    display: flex;
    justify-content: start;

    .user {
      width: 62px;
      height: 62px;
      background: var(--background-surface-2);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      .user_avatar {
        background-color: unset;
      }

      svg {
        height: 35px;
        width: 30px;
      }
    }

    .user_info {
      align-items: baseline;

      .username {
        font-style: normal;
        font-family: Inter-SemiBold;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 13px;
      }

      .userId {
        display: flex;
        align-items: center;

        span {
          font-style: normal;
          font-family: Inter-SemiBold;
          font-size: 12px;
          line-height: 15px;
          text-align: center;
          margin-right: 8px;
        }

        .copy {
          cursor: pointer;
        }

        .copied {
          margin-left: 8px;
        }
      }
    }

    .user_avatar {
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;
    }
  }

  .user_balance_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 24px 24px 0;

    .eye{
      svg{
        path, g, defs{
          fill: var(--icon-color-primary);
        }
      }
    }

    .icons_wrapper {
      display: flex;
      flex-direction: column;

      .eye{
        svg{
          path, g, defs{
            fill: var(--icon-color-primary);
          }
        }
      }

      .user_balance_refresh {
        display: flex;
        width: $user_balance_refresh_icon_size;
        height: $user_balance_refresh_icon_size;
        font-size: 19px;
        font-family: Inter-Bold;
        margin-top: 8px;

        svg {
          color: inherit;
        }

      }

      .user_balance_loading {
        animation: rotateSpin 0.3s infinite;
      }
    }

    .user_settings__balance_info {
      background: var(--background-surface-2);
      border: 1px solid var(--border-primary);
      border-radius: 8px;
      padding: 16px 12px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      height: 77px;
      // width: 49%;

      .balance {
        display: flex;
        flex-direction: column;

        .title {
          font-style: normal;
          font-family: Inter-Medium;
          font-size: 12px;
          line-height: 17px;
          padding: 0;
        }

        .value {
          font-style: normal;
          font-family: Inter-SemiBold;
          font-size: 16px;
          line-height: 19px;
          margin-top: 8px;
          text-align: initial;
          padding-left: 2px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 100px;

          .coins_wrapper {
            justify-content: flex-start !important;

            svg {
              height: 15px !important;
            }
          }
        }
      }
    }

    .user_settings__withdrawableBalance_info {
      background: var(--background-surface-2);
      border: 1px solid var(--border-primary);
      border-radius: 8px;
      padding: 12px 14px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      height: 41px;
      width: 100%;
      margin-top: 8px;
    }

    &.bonus_wallet {
      margin: 14px 24px 0;

      .user_settings__balance_info {
        width: 100%;
      }
    }
  }

  .user_settings__withdrawableBalance_info {
    background: var(--background-surface-2);
    border: 1px solid var(--border-primary);
    border-radius: 8px;
    padding: 14px 12px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin: 8px 0 0;
    height: 41px;
  }

  .user_icon {
    height: $headerBtnIconMobileHeight;
    width: $headerBtnIconMobileHeight + 10;
    margin: 0 4px;

    button {
      height: inherit;
      padding: 0;

      & > div {
        display: flex;
        flex-direction: column;
      }

      & > span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 9px;

        & > div {
          display: flex;
          flex-direction: column;

          & > div {
            display: flex;
            justify-content: flex-end;
            align-items: baseline;
            margin-bottom: 2px;
          }
        }

        p {
          width: $headerBtnIconMobileHeight + 5;
          max-width: $headerBtnIconMobileHeight + 5;
          margin: 10px 0 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 9px;
          font-family: Inter;
          text-align: center;
        }
      }

      .user_header_avatar {
        @include svg-size(16px);
      }

      .arrow {
        @include svg-size(12px);
        margin-left: 0px;
      }
    }
  }

  .user_settings {
    text-align: center;
    right: 0;
    width: 100%;
    top: -7px;
    // for some apple devices which can use svh
    height: 100vh;
    height: 100svh;
    position: fixed;
    overflow: auto;
    z-index: $user-settings--index;
    justify-content: flex-start;

    .modal_header {
      display: flex;
      justify-content: space-between;
      width: 80%;

      button {
        position: absolute;
        top: 60px;
        right: 17px;
        height: $close_menu_wrapper_size;
        width: $close_menu_wrapper_size;
        background-color: var(--background-surface-3);
        border-radius: 50%;
        border-radius: 50%;
      }
    }

    .user_settings_list {
      li {
        margin: 0 24px 10px;
        width: 88%;
        border-radius: 4px;

        .accordion {
          cursor: pointer;
          padding: 9.5px 19.5px 9.5px 21.5px;
          height: 43px;
          text-align: left;
          outline: none;
          font-size: 16px;
          transition: 0.4s;
          display: flex;
          justify-content: space-between;
          font-family: Inter-Bold;
          align-items: center;

          .category {
            display: flex;
            align-items: center;

            svg {
              height: 14px;
              margin-right: 14px;

              path {
                fill: var(--text-secondary);
                stroke: var(--text-secondary);
              }
            }
          }

          .menu_content_text {
            width: 100%;
            display: flex;
            justify-content: space-between;
          }

          .arrow {
            height: 100%;

            div {
              margin: 0;
            }
          }

          .rotateArrow {
            transform: rotate(180deg);
            border-radius: 3px 3px 0 0;
          }
        }

        .panel {
          background-color: var(--background-surface-1);
          padding-top: 10px;

          li {
            background-color: inherit;
            height: 25px;
          }
        }
      }

      // li:nth-child(5) {
      //   .category svg path {
      //     stroke: var(--text-secondary) !important;
      //   }
      // }

      .logout_btn {
        button {
          margin: 4px;
          justify-content: center !important;

          svg {
            margin-right: 12px;
          }
        }
      }
    }

    .user_settings__separator_line {
      width: 85%;
      border-bottom: 1px solid var(--border-primary);
      margin: 20px auto;
    }

    .panel {
      overflow: hidden;
      list-style: none;
      text-align: initial;
      padding: 20px 16px 20px 56px !important;

      .panel-list-item {
        height: auto !important;
        min-height: unset;
        font-size: 14px;
        line-height: 17px;
        font-family: Inter-Medium;
        align-items: center;
        display: flex;
        cursor: pointer;
        border: none;
        margin: 0px 0px 16px;

        &:last-child {
          margin-bottom: 0px !important;
        }
      }

      .active_tag {
        color: var(--secondary-5);
      }
    }
  }
}

.close_menu_wrapper {
  background-color: var(--background-surface-2);
  border-radius: 50%;
  height: $close_menu_wrapper_size;
  width: $close_menu_wrapper_size;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

@keyframes rotateSpin {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}
