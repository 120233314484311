@import url('../../../../../styles/mixins.scss');

.raffle_draw_tickets_container {
  width: 100%;
  background-color: var(--background-surface-2);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;

  .raffle_draw_tickets_header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid var(--border-primary);

    .player_tickets_row,
    .total_tickets_row {
      font-size: 20px;
      span {
        font-family: 'Inter-Medium';
        color: var(--text-brand);
      }
    }
  }

  .raffle_draw_tickets_table_wrapper {
    width: 100%;
    min-height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    padding: 16px 0px;

    .raffle_draw_tickets_table {
      width: 100%;
      height: 280px;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0px 16px;
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar {
        width: 8px;
        height: 5px;
        background-color: var(--background-surface-2);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        height: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: var(--background-surface-3);
      }
    }

    .table_ticket_item {
      width: 100%;
      height: 60px;
      background: var(--background-surface-1);
      margin-bottom: 12px;
      border-radius: 8px;
      padding: 0px 24px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;

      &::before {
        content: '';
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: var(--background-surface-2);
        position: absolute;
        left: -10px;
        top: 50%;
        transform: translateY(-50%);
      }

      &::after {
        content: '';
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: var(--background-surface-2);
        position: absolute;
        right: -10px;
        top: 50%;
        transform: translateY(-50%);
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }

    .empty_text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
