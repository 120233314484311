@import '../../../../../styles/mixins.scss';

.final-tour {
  width: 640px;
  height: 525px;
  padding: 20px 26px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  &-container_img {
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    // Remove
    height: 100%;
  }

  &__button-wrapper {
    z-index: 1;
    margin-bottom: 20px;
    width: 100%;
    button {
      background: var(--button-primary);
      color: var(--button-primary-text);
      width: 100%;
      border-radius: 4px;
      padding: 7px;
    }

    & + .final-tour__dont-show-again-wrapper {
      margin-top: 0;
    }
  }

  &__dont-show-again-wrapper {
    position: absolute;
    bottom: 10px;
    label {
      margin-left: 10px;
      color: var(--text-secondary);
    }
  }
}

.mobile .final-tour {
  width: initial;
  padding: 20px;
  height: 440px;

  .final-tour__button-wrapper {
    width: 100%;
    height: 41px;
  }
}
