@import '../../../../styles/mixins.scss';
@import '../../../../styles/constants';

.popup_container {
  @include center();
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .popup {
    background-color: var(--background-surface-1);
    border: 1px solid var(--border-primary);
    border-radius: 8px;

    &.small {
      padding: 24px 20px;
      width: 450px;
      max-width: 450px;
      min-height: 230px;

      .popup_heading_with_title,
      .popup_heading_without_title {
        width: 448px;
        position: relative;
        top: -24px;
        right: 20px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        .popup_close {
          top: 12px;
          right: 12px;
        }

        .popup_icon {
          margin: 27px auto 24px;
        }
      }

      .popup_footer .popup-btn {
        margin: auto;
      }
    }

    &.default {
      padding: 24px;
      width: 530px;

      .popup_heading_with_title,
      .popup_heading_without_title {
        position: relative;
        top: -24px;
        right: 24px;
        width: 527px;
        padding: 10px 20px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        text-align: center;

        .popup_close {
          top: 5px;
          right: 5px;
        }
      }
      .popup_body {
        padding: 10px 0 25px;
      }
    }

    &.fullSize,
    &.withImage {
      padding: 0;

      .popup_close {
        right: 25px;
        top: 30px;
      }

      .popup_body {
        padding: 0;
      }
    }
    &.final-tour-container {
      .popup_close {
        top: 15px;
        border: 1px solid var(--text-primary);
        svg path {
          fill: var(--text-primary);
        }
      }
    }

    .popup_close {
      @include center();

      position: absolute;
      background: var(--account-background-surface-3);
      border-radius: 50%;
      height: 30px;
      width: 30px;
      right: 0px;
      top: 0px;
      cursor: pointer;
      // z-index: $popup__popup-close--index;
      z-index: $icon-index;
    }

    .popup_title {
      width: 100%;
      color: var(--text-secondary);
      margin-top: 8px;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      padding: 10px 20px;
    }

    &_heading {
      &_with_title,
      &_without_title {
        background: var(--background-surface-2);
        position: relative;
        width: inherit;

        display: flex;
        justify-content: space-between;
        position: relative;
        z-index: $popup__heading-title--index;

        .popup_icon {
          margin: 25px auto 0;
        }
      }
    }

    .popup_body {
      padding: 10px 0 15px;

      .popup_description {
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        color: var(--text-primary);
        margin: 0;
        text-align: center;
      }
      .final-tour-container_img {
        width: 100%;
      }
    }

    .popup_footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;

      .popup-btn {
        border-radius: 8px;
        width: 100%;
        margin: 0 10px;
      }
    }
  }
}

.popup_container_backdrop {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(2px);
  will-change: opacity;
  z-index: $popup__popup-container-backdrop--index;
}

.mobile {
  .popup_container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    height: 100svh;
    top: 0;

    .popup {
      &_heading_with_title,
      &_heading_without_title {
        &.withoutImage {
          .popup_close {
            top: 20px;
          }
        }

        .popup_close {
          height: 24px;
          width: 24px;
          right: 10px;

          @supports (-webkit-touch-callout: none) {
            top: 20px;
          }

          // @supports not (-webkit-touch-callout: none) {
          //   top: 35px;
          // }
        }
      }
      &.final-tour-container {
        .popup_close {
          left: 90vw;
        }
      }

      &.small {
        width: 95vw;

        .popup_heading_with_title,
        .popup_heading_without_title {
          width: 94vw;
        }
      }

      &.default {
        padding: 15px;
        width: 95vw;

        .popup_heading_with_title,
        .popup_heading_without_title {
          top: -14px;
          right: 16px;
          width: 94.5vw;
          padding: 0;

          .popup_title {
            max-width: 100vw;
            margin: 30px 0 0;
            padding: 10px 20px 20px;
          }
        }
      }

      &.fullSize {
        padding: 0;
        width: 90vw;
        height: 90%;

        .withImage {
          position: absolute;
          top: 206px;
          z-index: $popup__heading-title--index;
          right: 4px;
        }

        .withoutImage {
          z-index: $popup__heading-title--index;
          position: relative;
          right: 1px;
          top: 0;

          .popup_close {
            top: 15px;
          }
        }
      }

      &.final-tour-container {
        height: auto;
        position: absolute;
        top: 95px;
        width: 428px;
        max-width: 97vw;
        .final-tour-container_img {
          width: 428px;
          max-width: 97vw;
        }
      }

      &.withImage {
        .withImage {
          position: absolute;
          top: 206px;
          z-index: $popup__heading-title--index;
          right: 4px;
        }

        .withoutImage {
          z-index: $popup__heading-title--index;
          position: absolute;
          right: 1px;
          top: 0;
        }
      }

      .popup_title {
        font-size: 20px;
        font-weight: 600;
        max-width: 70vw;
      }

      .popup_footer {
        flex-direction: column;

        .popup-btn {
          width: 100%;
          margin: 10px 0;
        }
      }
      &.gameCare-body {
        margin-top: 84px;
      }
    }
  }
}

.desktop,
.tablet {
  .popup_container {
    height: 100vh;

    .popup {
      @include scrollbar();

      max-height: 94vh;
      overflow: hidden;
      opacity: 0;
      animation: modal-content 0.4s 0.2s forwards;
    }
  }
}

.tablet {
  .popup_container {
    .popup {
      .popup_close {
        right: 3px;
        top: 5px;
      }
    }
  }
}

@keyframes modal-content {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
