.otp_popup {
  &_countdown {
    display: flex;
    align-items: baseline;
    justify-content: space-evenly;
    button {
      width: 120px;
      font-size: 12px;
    }
    .ant-statistic-content-value {
      color: var(--text-primary);
    }
  }
  button {
    &:disabled {
      background-color: var(--button-disable);
    }
  }
}