@import '../../../styles/mixins.scss';
@import '../../../styles/constants';

.dropdown_select__wrapper {
  height: 80px;

  .label {
    font-size: 14px;
    color: var(--text-secondary);
    margin-bottom: 8px;
    text-transform: capitalize;

    span {
      color: red;
    }
  }

  .error {
    font-size: 11px;
    line-height: 1.5;
    color: red;
  }
}

.dropdown_select {
  display: block;
  width: 100% !important;
  font-size: 12px;
  line-height: 35px;
  padding: 0 0 0 8px;
  border: 1px solid var(--bg-main-color-3);
  border-radius: 10px;
  height: 40px;
  outline: none;
  box-shadow: none;
  transition: border-color 0.2s, background-color 0.2s, opacity 0.25s;
  padding: 0px 16px;
  cursor: pointer;
  border: 1px solid var(--input-border-default);
  color: var(--text-primary);
  background-color: var(--input-background-surface-1);

  &:hover {
    border:1px solid var(--input-border-hover);
  }

  img {
    height: 15px;
    width: 24px;
    margin-right: 10px;
  }
}

.dropdown_select__list {
  @include scrollbar;

  max-height: 200px;
  background-color: var(--background-surface-2);
  overflow: auto;
  width: 100%;
  padding: 2px 0px;
  margin-top: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  li {
    font-size: 12px;
    font-family: Inter;
    line-height: 35px;
    width: 100% !important;
    padding: 0px 17px !important;

    &:hover {
      background: var(--button-secondary-hover);
    }

    img {
      height: 15px;
      width: 24px;
      margin-right: 10px;
    }
  }
}
