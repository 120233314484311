@import '../../../styles/sizes.scss';
@import '../../../styles/mixins.scss';

.mailBtn_wrapper {
  width: 45px;
  margin: 0 5px;
  position: relative;
  .mail_icon {
    height: 43px;
    width: 100%;
  }
  .secondary-btn {
    svg {
      g path,
      path {
        fill: var(--button-secondary-text) !important;
      }
    }

    &:active {
      transform: scale(0.95);
    }

    &:hover {
      background-color: var(--button-secondary-hover);
    }
  }
  .actual_count {
    @include center();
    position: absolute;
    top: -4px;
    right: -4px;
    width: 15px;
    height: 15px;
    font-size: 10px;
    color: var(--text-secondary);
    font-weight: 700;
    background-color: #CD2026;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
  }
}

.mobile .mailBtn_wrapper {
  margin: 0 0 0 5px;

  .mail_icon {
    height: $headerBtnIconMobileHeight;
  }
}
