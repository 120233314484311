@import '../../../../../../../../styles/sizes.scss';
@import '../../../../../../../../configs/medias.scss';
@import '../../../../../../../../styles/mixins.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-right: 20px;

  &__item {
    max-width: $account_personal_info_item_width;
    width: 100%;
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &__support {
    color: var(--text-secondary);
    display: flex;
    align-items: center;
    p {
      margin: unset;
    }
    span {
      margin-left: 10px;
    }
  }
  .verifyBtn {
    background-color: var(--button-primary);
    color: var(--button-primary-text);
    width: fit-content;
    height: 30px;
    border-radius: 8px;
    &:disabled {
      background: var(--background-surface-3);
      color: var(--text-secondary);
    }
  }

  &__birthDate_item {
    width: $account_personal_info_birthDate_item_width;
  }

  .detail_container {
    width: 100%;
    border-radius: 8px;
    color: var(--text-secondary);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: var(--account-background-surface-2);
    padding-bottom: 8px;

    .title {
      text-transform: capitalize;
      color: var(--text-secondary);
      line-height: normal;
      padding: 8px 12px;
      display: flex;
      .verifed {
        svg {
          width: 20px;
          height: 20px;
          margin-left: 5px;
        }
      }
    }
    .value_container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 10px;
      align-items: center;
      position: relative;
      .edit_input {
        margin-right: 60px;
      }
      .edit_input_error {
        p {
          margin: 0;
          top: 0;
        }
      }
      .edit_icons_wrapper {
        display: flex;
        margin: 0 0 10px;
        position: absolute;
        right: 10px;
        top: 10px;
      }
      .icon {
        cursor: pointer;
        @include svg-color(var(--icon-color-primary));
      }
      .success {
        @include svg-color(var(--text-brand));
        margin-right: 3px;
      }
      .cancel {
        @include svg-color(var(--text-error));
        width: 24px;
        height: 24px;
      }
    }

    .value {
      padding: 8px 12px;
    }

    .tooltip_wrapper {
      width: 100%;
    }
  }
}

@media (max-width: $tablet) {
  .container {
    padding: 0 1rem;

    &__item {
      width: 100%;
    }

    &__birthDate_item {
      width: 31%;
    }

    .detail_container {
      .value_container {
        .edit_input {
          margin-right: 60px;
        }
        .edit_input_error {
          p {
            text-align: left;
            margin: 0;
            top: 0;
          }
        }
      }
    }
  }
}
